import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'redux-first-history';
import { createTracker, EventTypes } from 'redux-segment';

const mapper = {
  [LOCATION_CHANGE]: EventTypes.page,
  [CALL_HISTORY_METHOD]: EventTypes.page,
};

const tracker = window.analytics
  ? createTracker({ mapper })
  : () => (next) => (action) => {
      action &&
        action.meta &&
        action.meta.analytics &&
        console.debug(
          `[Analytics] ${action.meta.analytics.eventType}`,
          action.meta.analytics.eventPayload
        );
      return next(action);
    };

export default tracker;
