import { get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { TOKEN_SET } from '~/actions/auth';
import { login, logout } from '~/lib/api';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { token: '' };
export default handleActions(
  {
    [TOKEN_SET]: (state, action) => ({
      token: action.payload,
    }),
    [login.SUCCESS]: (state, action) => ({
      token: action.meta.authorization,
    }),
    [login.FAILURE]: () => initialState,
    [logout.SUCCESS]: () => initialState,
  },
  initialState
);

export const key = 'auth';

// ------------------------------------
// Selectors
// ------------------------------------

const selectTokenState = get(key);
export const selectToken = createSelector(selectTokenState, get('token'));
