import Text from '~/components/Text';
import styled from '~/lib/styled';

const Paragraph = styled(Text, { lineHeight: 'copy', mb: 2, is: 'p' })({
  '&:last-child': {
    marginBottom: 0,
  },
});

export default Paragraph;
