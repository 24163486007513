import isEmpty from 'lodash/isEmpty';

/**
 * Snake case's any body before its sent to the server
 */
export default function apiStripEmptyBody(request) {
  if (isEmpty(request.body)) {
    delete request.body; // eslint-disable-line no-param-reassign
  }

  return request;
}
