import React from 'react';

import Svg from './Svg';

export default function NetsuiteIcon(props) {
  return (
    <Svg viewBox="0 0 35 36" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Netsuite</title>
      <path d="M31.518 11.688c.026 0 .064 0 .077-.039.013-.013.013-.025.013-.038a.07.07 0 0 0-.038-.064c-.026-.013-.052-.013-.09-.013h-.026v.14h.064m-.038-.204c.064 0 .102 0 .128.013.09.025.09.115.09.14v.039c0 .026-.013.064-.065.09 0 0-.012.012-.025.012l.115.205h-.115l-.09-.192h-.064v.192h-.102v-.499h.128m.038.703a.445.445 0 0 0 .448-.447.437.437 0 0 0-.448-.448.453.453 0 0 0-.447.448c0 .243.204.447.447.447m-.345-.447c0-.192.153-.345.358-.345.192 0 .345.153.345.345a.344.344 0 0 1-.345.345.34.34 0 0 1-.358-.345m-3.107 2.851a1.292 1.292 0 0 1-1.24-.933h3.26l.448-.703h-3.708a1.292 1.292 0 0 1 1.24-.934h2.238l.447-.703h-2.736c-1.1 0-1.982.895-1.982 1.982 0 1.1.895 1.982 1.982 1.982h2.353l.447-.704h-2.749m-9.334.704h2.365l.448-.704h-2.749a1.285 1.285 0 0 1-1.292-1.278c0-.716.576-1.279 1.292-1.279h2.238l.447-.703h-2.736c-1.1 0-1.995.895-1.995 1.982 0 1.1.895 1.982 1.982 1.982m-14.487-.69H2.033a1.282 1.282 0 0 1-1.279-1.28c0-.715.576-1.278 1.279-1.278h2.2c.715 0 1.29.575 1.29 1.279 0 .703-.575 1.278-1.278 1.278m-2.263.69h2.301c1.1 0 1.982-.882 1.982-1.981 0-1.1-.882-1.982-1.982-1.982H1.982A1.992 1.992 0 0 0 0 13.286c0 1.1.895 1.982 1.982 1.982m8.042-1.279a1.342 1.342 0 1 0 0-2.685H6.687v3.964h.767v-3.26h2.52c.357 0 .639.28.639.638 0 .359-.282.64-.64.64H7.825l2.276 1.982h1.113l-1.535-1.292h.345m12.62.601v-3.273h-.767v3.593a.396.396 0 0 0 .396.384h3.479l.447-.704h-3.554m-9.334-.703h2.033l-1.074-1.739-1.982 3.133h-.908l2.404-3.76a.565.565 0 0 1 .473-.242c.192 0 .358.089.46.242l2.417 3.772h-.895l-.422-.703h-2.059l-.447-.703M.371 17.659h1.01l1.227 2.57v-2.57h.653v3.273h-.96l-1.265-2.724v2.711H.371v-3.26M4.002 17.659h2.187v.537H4.705v.78h1.394v.537H4.705v.882H6.24v.537H4.002v-3.273M7.595 18.196h-.972v-.537h2.66v.537h-.972v2.736h-.703v-2.736h-.013" />
      <path
        fill="#1A1919"
        d="M10.127 19.973c.013.23.038.652.792.652.678 0 .768-.422.768-.588 0-.37-.294-.435-.755-.55-.498-.128-.716-.179-.882-.294a.734.734 0 0 1-.345-.64c0-.664.626-.958 1.24-.958.217 0 .652.038.92.32.18.204.192.421.205.562h-.473c-.025-.41-.37-.499-.69-.499-.448 0-.73.205-.73.525 0 .294.193.383.59.486.779.204.869.217 1.06.345.307.204.32.511.32.652 0 .575-.448 1.023-1.291 1.023-.243 0-.755-.039-1.01-.371-.192-.243-.192-.499-.192-.665h.473"
      />
      <path d="M13.323 17.659v2.02c0 .345.077.499.14.588.206.269.525.32.743.32.882 0 .882-.69.882-.934V17.66h.435v2.02c0 .179 0 .537-.269.87-.32.409-.818.447-1.061.447-.307 0-.857-.09-1.15-.524-.103-.141-.193-.333-.193-.819V17.66h.473M16.469 20.932h.447v-3.273h-.447zM18.681 20.932v-2.89h-1.1v-.383h2.634v.383h-1.099v2.89h-.435M20.765 17.659h2.046v.383h-1.598v1.023h1.521v.384h-1.521v1.087h1.636v.396h-2.084v-3.273" />
    </Svg>
  );
}
