import { all,fork } from 'redux-saga/effects';

import { reportForkErrors } from '~/utils/errorReporter';

import logout from './logout';
import openIdLogin from './openIdLogin';
import redirectOnLogin from './redirectOnLogin';
import {
  retrieveTokenFromLocalStorage,
  storeTokenInLocalStorage,
} from './token';
import unauthorisedSaga from './unauthorised';

export default function* rootAuthSaga() {
  /**
   * Take every LOGIN_REQUEST action and fire off a call
   * to the server and store the key.
   * Then listen for a LOGOUT_REQUEST action and fire off
   * a call to the server and remove the key.
   */
  const forks = yield all([
    fork(redirectOnLogin),
    fork(openIdLogin),
    fork(unauthorisedSaga),
    fork(storeTokenInLocalStorage),
    fork(retrieveTokenFromLocalStorage),
    fork(logout),
  ]);

  reportForkErrors(forks);
}
