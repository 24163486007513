import { camelizeKeys,decamelizeKeys } from 'humps';

/**
 * Snake case's any body before its sent to the server
 */
function snakeCaseBodyKeys(request) {
  return {
    ...request,
    body: decamelizeKeys(request.body),
  };
}

export { camelizeKeys as camelizePayload, snakeCaseBodyKeys };
