import { defaultTo } from 'lodash/fp';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { Types } from '../constants';
import slice from '../helpers/sliceReducer';

const pageReducer = handleActions(
  {
    [Types.REQUEST]: (state, { payload: { page } }) => page || state,
    [Types.FAILURE]: () => 1,
  },
  1
);

const totalReducer = handleActions(
  {
    [Types.SUCCESS]: (state, { payload: { total } }) => defaultTo(state, total),
    [Types.FAILURE]: () => 0,
  },
  0
);

const linksReducer = handleActions(
  {
    [Types.SUCCESS]: (state, { payload: { link } }) => link || state,
    [Types.FAILURE]: () => '',
  },
  ''
);

const perPageReducer = handleActions(
  {
    [Types.SUCCESS]: (state, { payload: { perPage } }) =>
      Number(perPage) || state,
  },
  25
);

const reducer = combineReducers({
  page: pageReducer,
  total: totalReducer,
  links: linksReducer,
  perPage: perPageReducer,
});

export default slice((state, action) => {
  const { payload, meta = {} } = action;

  if (!meta.pagination) {
    return state;
  }

  const { pagination } = meta;
  const { params = {}, page } = payload;

  return reducer(state, {
    ...action,
    payload: {
      ...pagination,
      page: page || params.page,
    },
  });
});
