import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Button, { ButtonGroup } from '~/components/Button';

import TextInput from '../TextInput';

const vaMid = { verticalAlign: 'middle' };

export default class Counter extends PureComponent {
  static propTypes = {
    /**
     * The current value of the Segmented Control when controlled.
     */
    value: PropTypes.number,

    /**
     * Function called when the value changes.
     */
    onChange: PropTypes.func,

    /**
     * The name of the radio group.
     */
    name: PropTypes.string,
  };

  handleChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value);
  };

  handleSubtract = () => {
    this.props.onChange && this.props.onChange(Number(this.props.value) - 1);
  };

  handleAdd = () => {
    this.props.onChange && this.props.onChange(Number(this.props.value) + 1);
  };

  render() {
    const { value, name, onChange, ...props } = this.props;

    return (
      <ButtonGroup>
        <Button type="button" onClick={this.handleSubtract}>
          -
        </Button>
        <TextInput
          variant="thin"
          height={32}
          css={vaMid}
          type="number"
          name={name}
          value={String(value)}
          onChange={this.handleChange}
          {...props}
        />
        <Button type="button" onClick={this.handleAdd}>
          +
        </Button>
      </ButtonGroup>
    );
  }
}
