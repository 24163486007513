import React from 'react';

import Flex from '~/components/Flex';
import styled from '~/lib/styled';

const FlexWrapper = styled(Flex)({
  '> div': {
    flexGrow: '1',
  },
});

export default ({ right, children }) => {
  const justify = right === true ? 'flex-end' : 'space-between';
  return <FlexWrapper justifyContent={justify}>{children}</FlexWrapper>;
};
