/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import LocaleCodes from 'locale-codes';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { IntlProvider } from '~/components/Intl';

import { makeSelectLocale } from './selectors';

const localeCodesForSelectList = LocaleCodes.all.map(
  ({ name, tag, location, loc = location ? `(${location})` : '' }) => ({
    label: `${name} ${loc || ''}`.trim(),
    value: tag,
  })
);

export { localeCodesForSelectList };

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return (
      <IntlProvider
        locale={this.props.locale}
        defaultLocale={this.props.locale}
        key={this.props.locale}
        messages={this.props.messages[this.props.locale]}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(makeSelectLocale(), (locale) => ({
  locale,
}));

export default connect(mapStateToProps)(LanguageProvider);
