/**
 * Input tag
 */
// Using the full path so that we can mock this in tests
import debounce from 'lodash/fp/debounce';
import PropTypes from 'prop-types';
import React from 'react';

import { getJsonItem, setJsonItem } from '~/lib/localstorage';

import BaseTextarea from './BaseTextarea';

const debounce250 = debounce(250);
export const STORAGE_KEY = 'dd.safeTextarea';

export default class Textarea extends React.Component {
  static propTypes = {
    ...BaseTextarea.propTypes,

    maxHeight: PropTypes.number,

    submitOnEnter: PropTypes.bool,

    requireMetaKey: PropTypes.bool,

    onSubmit: PropTypes.func,

    growWithText: PropTypes.bool,

    persistDraft: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  static defaultProps = {
    growWithText: false,
    persistDraft: false,
    maxHeight: 200,
    minHeight: 0,
  };

  state = {
    height: this.props.minHeight,
  };

  constructor(props) {
    super(props);

    this.textarea = React.createRef();
    this.key = props.persistDraft;

    if (props.persistDraft === true) {
      this.key = props.field?.name || props.name || 'default';
    }
  }

  componentDidMount() {
    this.adjustTextarea();

    if (this.props.persistDraft) {
      const { onChange, field } = this.props;
      const value = this.getStoredValue();

      if (value) {
        // NB: this will probably break soon as it's faking the
        // event target
        const mockEvent = {
          target: { name: this.textarea.current.name, value },
        };
        onChange && onChange(mockEvent);
        field?.onChange && field.onChange(mockEvent);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.persistDraft && this.textarea.current.value === '') {
      this.setStoredValue(this.key, '');
    }
  }

  getStoredValue = () => {
    const value = getJsonItem(STORAGE_KEY);

    if (value) {
      return value[this.key];
    }
  };

  setStoredValue = (key, val) => {
    const store = getJsonItem(STORAGE_KEY);

    if (val !== '') {
      setJsonItem(STORAGE_KEY, {
        ...store,
        [key]: val,
      });
    } else if (store) {
      delete store[key];
      setJsonItem(STORAGE_KEY, { ...store });
    }
  };

  handleKeyDown = (e) => {
    const metaKeyPressed = e.metaKey || e.ctrlKey;

    if (e.key === 'Enter') {
      if (!this.props.requireMetaKey || metaKeyPressed) {
        e.preventDefault();
        this.props.onSubmit(e);
      }
    }
  };

  handleChange = (e) => {
    const { onChange, growWithText, persistDraft, field } = this.props;

    onChange && onChange(e);
    field?.onChange && field.onChange(e);
    growWithText && this.adjustTextarea(e);
    persistDraft && this.persistDraft(e);
  };

  persistDraft = debounce250((e) => {
    this.setStoredValue(this.key, e.target.value);
  });

  adjustTextarea = () => {
    const { maxHeight } = this.props;
    const { current } = this.textarea;

    const newHeight = Math.min(current.scrollHeight, maxHeight);

    if (newHeight > this.state.height) {
      this.setState({ height: newHeight });
    }
  };

  render() {
    const {
      onSubmit,
      submitOnEnter,
      requireMetaKey,
      growWithText,
      field,
      form,
      maxHeight,
      persistDraft,
      getRef,
      ...props
    } = this.props;

    const keyDownProps = {};

    if (onSubmit && submitOnEnter) {
      keyDownProps['onKeyDown'] = this.handleKeyDown;
    }

    return (
      <BaseTextarea
        {...props}
        {...field}
        {...keyDownProps}
        ref={(element) => {
          this.textarea.current = element;
          if (getRef) getRef(element);
        }}
        onChange={this.handleChange}
        height={`${this.state.height}px`}
      />
    );
  }
}
