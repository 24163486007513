import Box from '~/components/Box';
import styled from '~/lib/styled';

const Section = styled(Box, {
  is: 'section',
  width: ['auto', '50%'],
  minWidth: ['170px', '600px'],
  mx: [3, 'auto'],
})();

export default Section;
