import { createAction } from 'redux-actions';

// ------------------------------------
// Types
// ------------------------------------
export const UPDATE_APP_ERROR = 'UPDATE_APP_ERROR';

// ------------------------------------
// Actions
// ------------------------------------
export const updateAppError = createAction('UPDATE_APP_ERROR');
