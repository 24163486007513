/**
 * Checkbox label which set styles for children
 */
import PropTypes from 'prop-types';
import React from 'react';

import Text from '~/components/Text';
import ourStyled from '~/lib/styled';

const Label = ourStyled(Text, {
  is: 'label',
  display: 'inline-block',
  lineHeight: 'copy',
})({
  "input[type='checkbox'], input[type='radio']": {
    float: 'left',
    marginTop: '0.3em',
  },
});

const TextWrapper = ({ children }) => <Text ml="1.5em">{children}</Text>;

const CheckboxLabel = (props) => <Label {...props}>{props.children}</Label>;

CheckboxLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

CheckboxLabel.TextWrapper = TextWrapper;

export default CheckboxLabel;
