import { camelizeKeys } from 'humps';
import { parse } from 'qs';

export default function parseQuery(location) {
  return camelizeKeys(
    parse(location.search, {
      ignoreQueryPrefix: true,
    })
  );
}
