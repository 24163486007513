import Box from '~/components/Box';
import Pane from '~/components/Pane';
import Text from '~/components/Text';
import styled, {
  alignContent,
  alignItems,
  borders,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  fontSize,
  justifyContent,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  order,
  width,
} from '~/lib/styled';

const Table = styled(Box, {
  is: 'table',
  minWidth: '100%',
  fontSize: 0,
  lineHeight: 'copy',
})(
  lineHeight,
  display,
  flexDirection,
  flex,
  flexBasis,
  flexWrap,
  alignItems,
  alignContent,
  justifyContent,
  width,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  fontSize
);

const Row = styled(Text, {
  is: 'tr',
})(
  display,
  flexDirection,
  flex,
  flexWrap,
  alignItems,
  alignContent,
  justifyContent
);

const Cell = styled(Text, {
  is: 'td',
  px: [1, 2],
  py: 2,
  borderBottom: 'default',
})(
  {
    wordBreak: 'normal',
  },
  display,
  flex,
  order,
  borders
);

const HeaderCell = styled(Text, {
  is: 'th',
  height: ['auto', '32px'],
  lineHeight: 'title',
  fontWeight: 500,
  letterSpacing: 'normal',
  textAlign: 'left',
  px: 2,
  py: [2, 0],
  justifyContent: 'middle',
})(
  {
    whiteSpace: 'nowrap',
  },
  lineHeight,
  display,
  flex,
  order,
  justifyContent,
  width
);

const Header = styled(Pane, {
  is: 'thead',
  bg: 'neutral.lightest',
  borderBottom: 'default',
})(flex, display, flexDirection);

const Body = styled(Pane, {
  is: 'tbody',
})(flex, display, flexDirection);

const Footer = styled(Pane, {
  is: 'tfoot',
})(flex, display, flexDirection);

export default Object.assign(Table, {
  Row,
  Cell,
  Body,
  Header,
  HeaderCell,
  Footer,
});
