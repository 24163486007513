import LR from 'logrocket';

const LR_SESSION_BLACKLIST = /^Session quota exceeded/;
const REDACTED = 'REDACTED';

let LogRocket = import.meta.env.MODE !== 'test' ? LR : null;

// Useful for tests to reset the LogRocket instance, preventing test bleed
export function createLogRocket(LRInstance = LR) {
  LogRocket = LRInstance;
  return LogRocket;
}

export function createRecordingMiddleware() {
  return LogRocket
    ? LogRocket.reduxMiddleware({
        stateSanitizer,
        actionSanitizer,
      })
    : (_store) => (next) => (action) => next(action);
}

export const SessionRecorder = {
  blacklistUrls: [],

  setup({ location, blacklistUrls = [], environment, dsn }) {
    const pathname = location.pathname;

    this.blacklistUrls = blacklistUrls;

    if (!blacklistUrls.some((p) => pathname.match(p))) {
      const slug = getSlug(dsn, environment);
      LogRocket && LogRocket.init(`${slug}/${slug}`);
    }
  },

  identify(...args) {
    const [uuid, rest] = args;
    // If we have RUM we should add user identity to the session aswell
    window.DD_RUM?.onReady(() => {
      window.DD_RUM?.setUser({
        id: uuid,
        ...rest,
      });
    });

    return LogRocket ? LogRocket.identify(...args) : null;
  },

  getRecordingUrl() {
    if (
      LogRocket &&
      LogRocket.sessionURL &&
      !LogRocket.sessionURL.match(LR_SESSION_BLACKLIST)
    ) {
      return LogRocket.sessionURL;
    }
  },
};

export default SessionRecorder;

function getSlug(dsn, env) {
  const base = `${dsn}-${env}`;
  const dom = new Date().getDate();

  if (env !== 'production') {
    return base;
  }

  if (dom >= 20 && dom < 28) {
    return base;
  } else if (dom >= 28 && dom < 31) {
    return `${base}-server-2`;
  } else if (dom >= 1 && dom < 4) {
    return `${base}-server-2`;
  } else if (dom >= 4 && dom < 12) {
    return `${base}-server-3`;
  } else if (dom >= 12 && dom < 20) {
    return `${base}-server-4`;
  } else {
    return base;
  }
}

function stateSanitizer({ auth, ...state }) {
  return state;
}

function actionSanitizer(action) {
  if (action.type === 'TOKEN_SET' && action.payload.length) {
    return {
      ...action,
      payload: REDACTED,
    };
  }

  if (/^(LOGIN|REGISTER|OAUTH)/.test(action.type)) {
    return {
      ...action,
      meta: REDACTED,
      payload: REDACTED,
    };
  }

  return action;
}
