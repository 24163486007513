import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Heading from '~/components/Heading';
import {
  Header,
  HeaderIcon as Icon,
  InnerWrapper,
  Section,
  Title,
} from '~/components/Layouts/Centered';
import { NavLink } from '~/components/Link';
import LoadingIndicator from '~/components/LoadingIndicator';
import Paragraph from '~/components/Paragraph';
import { useSearchQuery } from '~/components/SearchQuery';
import { routes } from '~/lib/api';

export function PublicContactPage() {
  const query = useSearchQuery();
  const { hash, integration, status } = useParams();
  const [charge, setCharge] = useState({});
  useEffect(() => {
    routes.payments
      .create({ contactHash: hash, integration, status, ...query })
      .then(({ body }) => {
        setCharge(body);

        if (body.redirectTo) {
          window.location = body.redirectTo;
        }
      });
  }, [hash, integration, status, query]);

  if (charge?.status === 'succeeded') {
    return (
      <Section>
        <Header>
          <Icon name="filled-check-1" size="5x" />
          <Title>Payment complete!</Title>
        </Header>

        <InnerWrapper>
          <Paragraph>
            Your payment has been processed and will be reconciled shortly. Feel
            free to navigate back to your{' '}
            <NavLink to={`/public/client/${hash}`}>client portal</NavLink>
          </Paragraph>
        </InnerWrapper>
      </Section>
    );
  }

  if (charge?.status === 'failed') {
    return (
      <Section>
        <Header>
          <Icon error name="filled-cross" size="5x" />
          <Title>Payment error</Title>
        </Header>

        <InnerWrapper>
          <Paragraph>
            Sorry, it appears we can&rsquo;t fulfil your request at the moment.
            Please return to your{' '}
            <NavLink to={`/public/client/${hash}`}>
              client portal and try again.
            </NavLink>
          </Paragraph>
          <Paragraph>Gateway response: {charge.errorMessage}.</Paragraph>
        </InnerWrapper>
      </Section>
    );
  }

  return (
    <div>
      <LoadingIndicator block p={4} />
      <Heading textAlign="center">Processing payment…</Heading>
    </div>
  );
}

// Wrap the component to inject dispatch and state into it
export default PublicContactPage;
