import React from 'react';

import Svg from './Svg';

export default function Dynamics365Icon(props) {
  return (
    <Svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Dynamics365</title>
      <g transform="scale(0.45)" clipPath="url(#a)">
        <defs>
          <linearGradient
            id="c"
            x1={38.045}
            x2={56.658}
            y1={-1}
            y2={47.723}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0B53CE" />
            <stop offset={1} stopColor="#7252AA" />
          </linearGradient>
          <linearGradient
            id="f"
            x1={64.138}
            x2={64.138}
            y1={93.492}
            y2={35.415}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2266E3" />
            <stop offset={1} stopColor="#AE7FE2" />
          </linearGradient>
          <linearGradient
            id="g"
            x1={82}
            x2={62.076}
            y1={56.786}
            y2={56.786}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#94B9FF" />
            <stop offset={0.288} stopColor="#94B9FF" stopOpacity={0.524} />
            <stop offset={1} stopColor="#538FFF" stopOpacity={0} />
          </linearGradient>
          <filter id="d">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              result="effect1_foregroundBlur"
              stdDeviation={0.4}
            />
          </filter>
          <filter id="e">
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur result="effect1_foregroundBlur" stdDeviation={4} />
          </filter>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h96v96H0z" />
          </clipPath>
        </defs>
        <mask
          id="b"
          width={70}
          height={96}
          x={12}
          y={0}
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M82 31.047a10 10 0 0 0-6.637-9.417L17.345.909C14.74-.021 12 1.909 12 4.676v31.505a4 4 0 0 0 2.655 3.767l26 9.286c2.605.93 5.345-1.001 5.345-3.767v-18.09a2 2 0 0 1 2.704-1.872l6.818 2.566A10 10 0 0 1 62 37.43v7.878L32.627 56.04A4 4 0 0 0 30 59.797V91.28c0 2.778 2.763 4.71 5.373 3.757L75.432 80.4A10 10 0 0 0 82 71.007v-39.96Z"
          />
        </mask>
        <g mask="url(#b)">
          <path
            fill="url(#c)"
            d="m12-1 70 25v34.325c0 2.765-2.74 4.697-5.345 3.767L62 56.859V37.424a10 10 0 0 0-6.483-9.361l-6.814-2.56A2 2 0 0 0 46 27.375v23.768L12 39V-1Z"
          />
          <g filter="url(#d)">
            <path
              fillOpacity={0.24}
              d="M82 31.4c0 4.2-2.624 7.959-6.568 9.4L30 57.4v40l52-19v-47Z"
            />
          </g>
          <g filter="url(#e)">
            <path
              fillOpacity={0.32}
              d="M82 33c0 4.2-2.624 7.959-6.568 9.4L30 59v40l52-19V33Z"
            />
          </g>
          <path
            fill="url(#f)"
            d="M82 31c0 4.2-2.624 7.959-6.568 9.4L30 57v40l52-19V31Z"
          />
          <path
            fill="url(#g)"
            d="M82 31c0 4.2-2.624 7.959-6.568 9.4L30 57v40l52-19V31Z"
            opacity={0.5}
          />
          <path
            fill="#B0ADFF"
            d="m62.001 45.32-16.009 5.857v23.458a2 2 0 0 0 2.705 1.872l6.827-2.57a10 10 0 0 0 6.477-9.36V45.32Z"
            opacity={0.5}
          />
        </g>
      </g>
    </Svg>
  );
}
