export default (partReducer, noRefReducer) =>
  (state = {}, action) => {
    const { meta } = action;

    if (!meta || !meta.ref) {
      return noRefReducer ? noRefReducer(state, action) : state;
    }

    const key = action.meta.ref;
    const part = state[key];

    return {
      ...state,
      [key]: partReducer(part, action),
    };
  };
