import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/components/Button';
import Popover from '~/components/Popover';

import applyLink from '../applyLink';
import PopoverDialog from '../Link/Dialog';

export default class LinkButton extends React.Component {
  static displayName = 'LinkButton';

  static propTypes = {
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
  };

  state = {
    open: false,
    href: '',
  };

  handleClick = (e) => {
    e.stopPropagation();
    this.setState({
      open: true,
    });
  };

  confirm = (href) => {
    const editorState = applyLink(this.props.getEditorState(), href, '_blank');

    this.props.setEditorState(editorState);

    this.setState({ open: false });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  overlay = ({ close }) => (
    <PopoverDialog close={close} confirm={this.confirm} />
  );

  preventBubblingUp = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Popover content={this.overlay} bringFocusInside>
        <Button type="button" py={1}>
          Link
        </Button>
      </Popover>
    );
  }
}
