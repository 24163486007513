import styled, { themed, variant } from '~/lib/styled';

import Input from '../Input';

const Textarea = styled(Input, {
  is: 'textarea',
  width: '100%',
  bg: 'white.base',
  border: 'none',
  borderRadius: 2,
  boxShadow: 'textInput',
  px: 2,
  py: 2,
  appearance: 'default',
  disabled: false,
  spellCheck: true,
})(
  themed((p) => ({
    '&:focus, &[data-invalid]:focus': {
      boxShadow: 'textInputFocus',
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: 'neutral.N4',
    },

    '&[data-invalid]': {
      boxShadow: 'textInputError',
    },
  })),
  variant({
    prop: 'appearance',
    variants: {
      minimal: {
        '&[data-empty=true]:not(:focus)': {
          resize: 'none',
        },
      },
    },
  })
);

Textarea.defaultProps = {
  ...Textarea.defaultProps,
  blacklist: [
    ...Input.defaultProps.blacklist,
    ...Textarea.defaultProps.blacklist,
  ],
};

export default Textarea;
