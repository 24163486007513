export default function checkResponse(response) {
  if (response.ok) {
    return response;
  }

  let message = response.resolvedBody.error || response.statusText;

  if (import.meta.env.MODE !== 'production' && response.status >= 500) {
    message = message + '\n\n' + response.resolvedBody;
  }

  const error = new Error(message);
  error.status = response.status;
  error.response = response;
  throw error;
}
