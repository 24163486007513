import mockLocalStorage from './tests/LocalStorageMock';

// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
const localStorage =
  import.meta.env.MODE === 'test' && typeof Cypress === 'undefined'
    ? mockLocalStorage
    : (function localStorageTest() {
        const mod = 'modernizr';
        try {
          window.localStorage.setItem(mod, mod);
          window.localStorage.removeItem(mod);
          return window.localStorage;
        } catch (e) {
          try {
            window.sessionStorage.setItem(mod, mod);
            window.sessionStorage.removeItem(mod);
            return window.sessionStorage;
          } catch (e2) {
            return mockLocalStorage;
          }
        }
      })();

if (!localStorage) {
  throw new Error('No localStorage or sessionStorage!');
}

export default class LocalStorage {
  constructor(storage) {
    this.storage = storage;
    this.getJsonItem = (key) => {
      // console.log(`[LocalStorage] Finding key ${key}`);
      return JSON.parse(this.storage.getItem(key));
    };
    this.setJsonItem = (key, value) => {
      if (typeof value !== 'undefined') {
        // console.log(`[LocalStorage] Setting key ${key}`, value);
        this.storage.setItem(key, JSON.stringify(value));
      }
    };
    this.removeItem = (key) => this.storage.removeItem(key);
  }
}

let storage = new LocalStorage(localStorage);

export function setStorage(newStorage) {
  storage = new LocalStorage(newStorage);
}

export const getJsonItem = storage.getJsonItem.bind(storage);
export const setJsonItem = storage.setJsonItem.bind(storage);
export const removeItem = storage.removeItem.bind(storage);
