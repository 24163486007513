import React from 'react';

import Heading from '~/components/Heading';

const Title = ({ children, ...props }) => (
  <Heading size={600} pt={4} mb={3} {...props}>
    {children}
  </Heading>
);

export default Title;
