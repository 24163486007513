import decorateComponentWithProps from '~/utils/decorateComponentWithProps';

import { ENTITY_TYPE } from './constants';
import Link from './Link';
import LinkButton from './LinkButton';
import linkStrategy from './linkStrategy';

export default () => {
  const store = {
    setReadOnly: undefined,
  };

  return {
    decorators: [
      {
        strategy: linkStrategy,
        component: decorateComponentWithProps(Link, { store }),
      },
    ],
    // eslint-disable-line consistent-return
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a' && node.getAttribute('href')) {
        return createEntity(ENTITY_TYPE, 'MUTABLE', {
          href: node.getAttribute('href'),
          target: node.getAttribute('target'),
        });
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === ENTITY_TYPE) {
        return `<a href="${entity.data.href}"${
          entity.data.target ? ` target="${entity.data.target}"` : ''
        }>${originalText}</a>`;
      }
      return originalText;
    },
    initialize: ({ setReadOnly }) => {
      store.setReadOnly = setReadOnly;
    },
    LinkButton,
  };
};
