/**
 *
 * Root layout
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages.
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';

import StatusBanner from '~/components/StatusBanner';
import styled, { ThemeProvider } from '~/lib/styled';
import theme, { evergreenTheme } from '~/theme';

// Import CSS reset and Global Styles
import GlobalStyles from './global-styles';

const AppWrapper = styled('div')({
  minHeight: '100%',
  height: '100%',

  '@media print': {
    height: 'auto',
  },
});

export class PublicLayout extends React.PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme} evergreenTheme={evergreenTheme}>
        <AppWrapper>
          <Helmet titleTemplate="%s - PayPortal" defaultTitle="PayPortal" />
          {React.Children.only(this.props.children)}
          <GlobalStyles />
        </AppWrapper>
      </ThemeProvider>
    );
  }
}

export class RootLayout extends React.PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme} evergreenTheme={evergreenTheme}>
        <AppWrapper>
          <Helmet
            titleTemplate="%s - CreditorWatch Collect"
            defaultTitle="CreditorWatch Collect"
          >
            <link
              rel="apple-touch-icon"
              sizes="57x57"
              href="/apple-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="60x60"
              href="/apple-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="72x72"
              href="/apple-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="76x76"
              href="/apple-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="114x114"
              href="/apple-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="120x120"
              href="/apple-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="144x144"
              href="/apple-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/apple-icon-152x152.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-icon-180x180.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="192x192"
              href="/android-icon-192x192.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="96x96"
              href="/favicon-96x96.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="shortcut icon" type="image/x-ico" href="/favicon.ico" />
            <meta name="msapplication-TileColor" content="#405158" />
            <meta
              name="msapplication-TileImage"
              content="/ms-icon-144x144.png"
            />
            <meta name="theme-color" content="#405158" />
          </Helmet>
          <StatusBanner />
          {React.Children.only(this.props.children)}
          <GlobalStyles />
        </AppWrapper>
      </ThemeProvider>
    );
  }
}

export default RootLayout;
