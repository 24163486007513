export default function isFocused(getEditorState, entity) {
  const { blockKey, anchorOffset, focusOffset } = entity;
  const editorState = getEditorState();
  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const focusKey = selection.getFocusKey();

  // Single line and on the block
  if (anchorKey === focusKey && anchorKey === blockKey) {
    return (
      selection.getAnchorOffset() === anchorOffset &&
      selection.getFocusOffset() === focusOffset
    );
  }

  return false;
}
