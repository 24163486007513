import React from 'react';

import Svg from './Svg';

export default function QuickbooksIcon(props) {
  return (
    <Svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Quickbooks</title>
      <circle
        stroke="null"
        fill="#2ca01c"
        cx="22.58188"
        cy="22.95703"
        r="22.41623"
        id="svg_254"
      />
      <path
        stroke="null"
        fill="#ffffff"
        d="m6.38895,22.95703a8.71711,8.71711 0 0 0 8.71711,8.71711l1.2441,0l0,-3.23914l-1.2441,0a5.48077,5.48077 0 1 1 0,-10.95873l2.99257,0l0,16.93546a3.23914,3.23914 0 0 0 3.23914,3.23914l0,-23.41375l-6.23171,0a8.71711,8.71711 0 0 0 -8.71711,8.71991zm23.66593,-8.71711l-1.2441,0l0,3.23914l1.2441,0a5.48077,5.48077 0 0 1 0,10.95873l-2.98976,0l0,-16.93826a3.23634,3.23634 0 0 0 -3.23915,-3.23634l0,23.41095l6.23171,0a8.71711,8.71711 0 1 0 0,-17.43422l-0.0028,0z"
      />
    </Svg>
  );
}
