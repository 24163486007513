import { mapValues } from 'lodash/fp';
import { handleActions } from 'redux-actions';

import { IMPORT_COMPLETE } from '~/actions/import';

import { StatusTypes,Types } from '../constants';
import slice from '../helpers/sliceReducer';

const updateAllToInvalidated = mapValues(() => StatusTypes.INVALIDATED);

const initialState = StatusTypes.NOT_LOADED;
export default slice(
  handleActions(
    {
      [Types.REQUEST]: () => StatusTypes.LOADING,
      [Types.SUCCESS]: () => StatusTypes.LOADED,
      [Types.FAILURE]: () => StatusTypes.FAILED,
      [Types.INVALIDATE]: () => StatusTypes.INVALIDATED,
    },
    initialState
  ),
  handleActions(
    // YUK: Special case where we don't know if data is correct anymore.
    {
      [IMPORT_COMPLETE]: (state) => updateAllToInvalidated(state),
    },
    initialState
  )
);
