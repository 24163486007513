import { pick } from 'lodash';

import { logout } from '~/lib/api';

export default function createAuthReducer(reducer) {
  return (state, action) => {
    if (action.type === logout.SUCCESS) {
      state = pick(state, ['router', 'language']);
    }

    return reducer(state, action);
  };
}
