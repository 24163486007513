import PropTypes from 'prop-types';
import React from 'react';

import Flex from '~/components/Flex';

import Button from '../../../../Button';
import TextInput from '../../../../TextInput';

export default class PopoverDialog extends React.Component {
  static propTypes = {
    href: PropTypes.string,
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    href: '',
  };

  state = {
    href: this.props.href,
  };

  handleChange = (e) => this.setState({ href: e.target.value });

  handleAdd = (e) => {
    e.stopPropagation();
    this.props.confirm(this.state.href);
    this.props.close(e);
  };

  handleRemove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.confirm();
  };

  handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleClose = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.close(e);
  };

  handleKeyUp = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleAdd(e);
    } else if (e.key === 'Escape') {
      e.preventDefault();
      this.props.close(e);
    }
  };

  render() {
    return (
      <Flex p={2}>
        <TextInput
          type="text"
          placeholder="Link url"
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          onClick={this.handleClick}
          value={this.state.href}
          autoFocus
          py={1}
          px={1}
          width={200}
          mr={1}
        />

        {this.props.href && (
          <Button
            appearance="minimal"
            intent="danger"
            mr={1}
            onClick={this.handleRemove}
          >
            Remove
          </Button>
        )}
        <Button appearance="minimal" mr={1} onClick={this.handleClose}>
          Cancel
        </Button>
        <Button
          appearance="primary"
          mr={1}
          intent="success"
          onClick={this.handleAdd}
        >
          Ok
        </Button>
      </Flex>
    );
  }
}
