import Box from '~/components/Box';
import styled, {
  borderColor,
  borders,
  boxShadow,
  display,
  overflow,
  overflowX,
  overflowY,
  variant,
} from '~/lib/styled';

const tintStyle = variant({
  prop: 'tint',
  key: 'tints',
});

export const Pane = styled(Box)(
  borders,
  borderColor,
  tintStyle,
  boxShadow,
  display,
  overflow,
  overflowX,
  overflowY
);

Pane.displayName = 'Pane';

export default Pane;
