import { isObject, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '~/components/Box';
import Button from '~/components/Button';
import Flex from '~/components/Flex';
import Heading from '~/components/Heading';
import Icon from '~/components/Icon';
import Img from '~/components/Img';
import { FormattedDate, FormattedNumber } from '~/components/Intl';
import { NavLink, PlainLink } from '~/components/Link';
import Pane from '~/components/Pane';
import Paragraph from '~/components/Paragraph';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHeader,
  TextTableHeaderCell,
} from '~/components/Table';
import Text from '~/components/Text';

const Banner = ({ daysOverdue, ...props }) => (
  <Pane
    p={2}
    bg={
      daysOverdue <= 0
        ? 'green.base'
        : daysOverdue > 0 && daysOverdue < 7
        ? 'orange.base'
        : 'red.base'
    }
    {...props}
  >
    <Text textAlign="center" color="#fff">
      {daysOverdue > 0 ? (
        <>
          <Icon name="alert-2-1" />
          This invoice is <strong>{daysOverdue}</strong> days late
        </>
      ) : (
        <>This invoice will be due in {Math.abs(daysOverdue)} days</>
      )}
    </Text>
  </Pane>
);

const Number = (props) => (
  <FormattedNumber minimumFractionDigits={2} {...props} />
);
const textAlignRight = { textAlign: 'right' };

const renderAddress = (address, i) =>
  address &&
  address.addressLine1 && (
    <Box key={i} mb={2}>
      {address.attentionTo && <div>Attention: {address.attentionTo}</div>}
      <div>{address.addressLine1}</div>
      <div>{address.addressLine2}</div>
      <div>{address.addressLine3}</div>
      <div>{address.addressLine4}</div>
      <div>
        {address.city} {address.postalCode}
      </div>
      <div>{address.region}</div>
      <div>{address.country}</div>
    </Box>
  );

const renderRow = ({ description, quantity, unitAmount, lineAmount }, i) => (
  <Row key={i}>
    <Cell flexGrow={1}>{description}</Cell>
    <Cell flexGrow={0} flexBasis={120}>
      {quantity}
    </Cell>
    <Cell flexGrow={0} flexBasis={120}>
      {isObject(unitAmount) ? unitAmount : <Number value={unitAmount} />}
    </Cell>
    <Cell flexGrow={0} flexBasis={120} textProps={textAlignRight}>
      <Number value={lineAmount} />
    </Cell>
  </Row>
);

const PublicInvoiceDetail = ({
  amountDue,
  canRetrievePdf,
  contact,
  currencyCode,
  date,
  daysOverdue,
  invoiceNumber,
  lineItems,
  organisation,
  reference,
  subTotal,
  total,
  totalTax,
  pdfLink,
  contactHash,
  canPayNow,
  handlePayNow,
}) => (
  <div>
    <ContactBanner contactHash={contactHash} />

    <Banner data-testid="banner" daysOverdue={daysOverdue} />

    <Pane borderBottom="default" bg="white.base">
      <Flex justifyContent="space-between" p={3} alignItems="center">
        <Heading size={600}>
          <Number value={amountDue} /> <small>{currencyCode}</small>
        </Heading>

        {canRetrievePdf && (
          <Button
            data-testid="button-pdf"
            is="a"
            href={pdfLink}
            target="_blank"
          >
            Download PDF
          </Button>
        )}
        {canPayNow && (
          <Button
            intent="success"
            appearance="primary"
            fontSize={2}
            onClick={handlePayNow}
            width={['100%', 'auto']}
          >
            Pay now &rarr;
          </Button>
        )}
      </Flex>
    </Pane>

    <Pane border="default" borderTop={0} width="80%" minWidth={600} m="0 auto">
      <Pane borderBottom="default">
        <Flex justifyContent="space-between" p={3}>
          <Heading size={800}>Tax Invoice</Heading>

          {organisation.logoUrl ? (
            <Img src={organisation.logoUrl} alt={organisation.name} />
          ) : (
            <Heading size={700}>{organisation.name}</Heading>
          )}
        </Flex>
      </Pane>

      <Flex justifyContent="space-between" p={3}>
        <Box>
          <Paragraph>
            <strong>{contact.name}</strong>
          </Paragraph>
          {map(contact.addresses, renderAddress)}
        </Box>

        <Box>
          {organisation.taxNumber && (
            <Paragraph>
              <strong>Tax Number</strong>
              <br />
              {organisation.taxNumber}
            </Paragraph>
          )}
          {organisation.registrationNumber && (
            <Paragraph>
              <strong>Registration Number</strong>
              <br />
              {organisation.registrationNumber}
            </Paragraph>
          )}

          {date && (
            <Paragraph>
              <strong>Invoice Date</strong>
              <br />
              <FormattedDate
                value={date}
                year="numeric"
                month="long"
                day="2-digit"
              />
            </Paragraph>
          )}
        </Box>
        <Box>
          {invoiceNumber && (
            <Paragraph data-testid="invoiceNumber">
              <strong>Invoice Number</strong>
              <br />
              {invoiceNumber}
            </Paragraph>
          )}

          {reference && (
            <Paragraph>
              <strong>Invoice Reference</strong>
              <br />
              {reference}
            </Paragraph>
          )}
        </Box>
      </Flex>

      <Box p={3}>
        <Table>
          <TableHeader>
            <TextTableHeaderCell flexGrow={1}>Description</TextTableHeaderCell>
            <TextTableHeaderCell flexGrow={0} flexBasis={120}>
              Quantity
            </TextTableHeaderCell>
            <TextTableHeaderCell flexGrow={0} flexBasis={120}>
              Unit amount
            </TextTableHeaderCell>
            <TextTableHeaderCell
              textProps={textAlignRight}
              flexGrow={0}
              flexBasis={120}
            >
              Amount ({currencyCode})
            </TextTableHeaderCell>
          </TableHeader>
          <TableBody>
            {map(lineItems, renderRow)}
            {renderRow({
              unitAmount: <strong>Subtotal</strong>,
              lineAmount: subTotal,
            })}
            {renderRow({
              unitAmount: <strong>Total tax</strong>,
              lineAmount: totalTax,
            })}
            {renderRow({
              unitAmount: <strong>Total {currencyCode}</strong>,
              lineAmount: total,
            })}
          </TableBody>
        </Table>
      </Box>

      <Flex justifyContent="space-between" p={3} data-testid="amount-due">
        <Heading size={600}>Amount due {currencyCode}</Heading>
        <Heading size={600}>
          <strong>
            <Number value={amountDue} />
          </strong>
        </Heading>
      </Flex>
    </Pane>

    <Text m={3} textAlign="center">
      <Box
        is={PlainLink}
        href="https://creditorwatchcollect.com.au"
        target="_blank"
        textDecoration="none"
        my={3}
        display="inline-block"
        data-testid="branding"
      >
        Powered by <Icon size="2x" name="logo-head" /> CreditorWatch Collect
      </Box>
    </Text>
  </div>
);

PublicInvoiceDetail.propTypes = {
  amountDue: PropTypes.number.isRequired,
  canRetrievePdf: PropTypes.bool.isRequired,
  contact: PropTypes.object.isRequired,
  currencyCode: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  daysOverdue: PropTypes.number.isRequired,
  invoiceNumber: PropTypes.string.isRequired,
  lineItems: PropTypes.array.isRequired,
  organisation: PropTypes.object.isRequired,
  reference: PropTypes.string,
  subTotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalTax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pdfLink: PropTypes.string.isRequired,
};

export default PublicInvoiceDetail;

function ContactBanner({ contactHash, ...props }) {
  if (!contactHash) {
    return null;
  }

  return (
    <Pane p={2} bg="neutral.base" {...props}>
      <NavLink to={`/public/client/${contactHash}`} color="#fff">
        &larr; Back to account
      </NavLink>
    </Pane>
  );
}
