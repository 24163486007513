import { all,fork } from 'redux-saga/effects';

import { fetchSaga } from '~/lib/api/saga';
import { promiseWatcherSaga } from '~/lib/promise/saga';
// import { authenticationSaga } from '~/lib/authentication/saga';
import { reportForkErrors } from '~/utils/errorReporter';

import { appLoadingSaga } from './loading';

export default function* rootSaga() {
  const forks = yield all([
    // fork(authenticationSaga),
    fork(fetchSaga),
    fork(promiseWatcherSaga),
    fork(appLoadingSaga),
  ]);

  reportForkErrors(forks);
}
