import { call,put, takeEvery } from 'redux-saga/effects';

import { TOKEN_SET,tokenSet } from '~/actions/auth';
import { login } from '~/lib/api';
import {
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from '~/lib/authentication';

export function* storeTokenInLocalStorage() {
  yield takeEvery(TOKEN_SET, storeToken);
  yield takeEvery(login.SUCCESS, unwrapToken);
}

export function* unwrapToken({ meta: { authorization: token } }) {
  yield call(setAuthToken, token);
}

export function* storeToken({ payload: token }) {
  yield call(setAuthToken, token);
}

export function* retrieveTokenFromLocalStorage() {
  const token = yield call(getAuthToken);

  if (token) {
    yield put(tokenSet(token));
  }
}

export function* removeTokenFromLocalStorage() {
  yield call(removeAuthToken);
}
