import { ContentState } from 'draft-js';
import { Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Popover from '~/components/Popover';

import applyLink from '../applyLink';
import PopoverDialog from './Dialog';

export default class LinkComponent extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    contentState: PropTypes.instanceOf(ContentState).isRequired,
    entityKey: PropTypes.string.isRequired,
    offsetKey: PropTypes.string.isRequired,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    store: PropTypes.object,
  };

  handleClick = (evt) => {
    this.props.store.setReadOnly(true);
  };

  confirm = (href) => {
    const editorState = applyLink(this.props.getEditorState(), href, '_blank');

    this.props.setEditorState(editorState);
    this.props.store.setReadOnly(false);
  };

  handleClose = () => {
    this.props.store.setReadOnly(false);
  };

  render() {
    const { children, offsetKey, contentState, entityKey } = this.props;
    const { href } = contentState.getEntity(entityKey).getData();

    return (
      <Popover
        content={({ close }) => (
          <PopoverDialog
            close={close}
            confirm={(href) => {
              this.confirm(href);
              close();
            }}
            href={href}
          />
        )}
        onOpen={this.handleClick}
        onCloseComplete={this.handleClose}
        bringFocusInside
      >
        <Pane
          is="a"
          color="DeepSkyBlue"
          textDecoration="underline"
          cursor="pointer"
          data-offset-key={offsetKey}
        >
          {children}
        </Pane>
      </Popover>
    );
  }
}
