export default function toConvertMiddleware(plugins) {
  return {
    htmlToStyle: (nodeName, node, currentStyle) =>
      plugins.reduce((resultingStyle, plugin) => {
        if (plugin.htmlToStyle) {
          return plugin.htmlToStyle(nodeName, node, resultingStyle);
        }

        return resultingStyle;
      }, currentStyle),
    textToEntity: (text, createEntity) =>
      plugins.reduce((results, plugin) => {
        if (plugin.textToEntity) {
          return results.concat(plugin.textToEntity(text, createEntity));
        }

        return results;
      }, []),
    htmlToEntity: (nodeName, node, createEntity) =>
      plugins.reduce((result, plugin) => {
        if (result) {
          return result;
        }

        if (plugin.htmlToEntity) {
          return plugin.htmlToEntity(nodeName, node, createEntity);
        }

        return null;
      }, null),
    styleToHTML: (style) =>
      plugins.reduce((node, plugin) => {
        if (plugin.styleToHTML) {
          const result = plugin.styleToHTML(style, node);

          if (result !== '') {
            return result;
          }
        }

        return node;
      }, null),
    blockToHTML: (block) =>
      plugins.reduce((text, plugin) => {
        if (text) {
          return text;
        }

        if (plugin.blockToHTML) {
          return plugin.blockToHTML(block, text);
        }

        return undefined;
      }, null),
    entityToHTML: (entity, originalText) =>
      plugins.reduce((text, plugin) => {
        if (plugin.entityToHTML) {
          return plugin.entityToHTML(entity, text);
        }

        return text;
      }, originalText),
  };
}
