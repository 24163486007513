import { handleActions } from 'redux-actions';

import { SET_CURRENT_ORGANISATION_ID } from '~/actions/currentOrganisation';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export const reducer = handleActions(
  {
    [SET_CURRENT_ORGANISATION_ID]: (state, action) => ({
      id: action.payload,
    }),
  },
  initialState
);

export default reducer;
