import { camelizeKeys } from 'humps';

/* eslint-disable no-param-reassign */
export default function normalizeResponse(response) {
  // No content
  if (response.status === 204) {
    return response.text().then(() => {
      response.resolvedBody = null;
      return response;
    });
  }

  return response
    .clone()
    .text()
    .then((body) => {
      try {
        response.resolvedBody = camelizeKeys(
          JSON.parse(body, dateTimeReviver),
          function (key, convert) {
            // Don't convert keys that contain only numbers and dashes:
            // e.g. like 5513-9342-2223-0234
            return /^[0-9_-]+$/.test(key) ? key : convert(key);
          }
        );
      } catch (e) {
        response.resolvedBody = body;
      }

      return response;
    });
}
/* eslint-enable no-param-reassign */

const reDateISO =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;
function dateTimeReviver(key, value) {
  if (typeof value === 'string' && reDateISO.test(value)) {
    return new Date(value);
  }

  return value;
}
