import { isArray, isFunction, isObject, mapValues, reduce, set } from 'lodash';
import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';

/**
 * Walks a tree of reducers, combining them at leaf nodes and reducing them
 * when arrays occur
 *
 * @param {object} reducers a key value object of `paths` and `reducers`
 */
export default function combineReducerTree(reducers) {
  const tree = reduce(
    reducers,
    (memo, reducer, path) => set(memo, path, reducer),
    {}
  );

  return mapValues(tree, recursiveCombinator);
}

function recursiveCombinator(reducers) {
  if (isFunction(reducers)) {
    return reducers;
  }

  if (isObject(reducers)) {
    return combineReducers(mapValues(reducers, recursiveCombinator));
  }

  if (isArray(reducers)) {
    return reduceReducers(reducers.map(recursiveCombinator));
  }

  throw new Error(
    `Expected an object, array or function. Got ${typeof reducers}`
  );
}
