/**
 * Input tag
 */
import styled, { themed } from '~/lib/styled';

import Input from '../Input';

const TextInput = styled(Input, {
  is: 'input',
  type: 'text',
  width: '100%',
  bg: 'white.base',
  border: 'none',
  borderRadius: 2,
  boxShadow: 'textInput',
  px: 2,
  py: 2,
  variant: 'default',
})(
  themed(() => ({
    '&:focus, &[data-invalid]:focus, &:invalid:focus': {
      boxShadow: 'textInputFocus',
      outline: 'none',
    },

    '&:disabled': {
      backgroundColor: 'neutral.N4',
    },

    '&[data-invalid], &:invalid': {
      boxShadow: 'textInputError',
    },
  }))
);

TextInput.defaultProps = {
  ...TextInput.defaultProps,
  blacklist: [
    ...Input.defaultProps.blacklist,
    ...TextInput.defaultProps.blacklist,
  ],
};

export default TextInput;
