import { push } from 'redux-first-history';
import { call,put, takeLatest } from 'redux-saga/effects';

import { logout } from '~/lib/api';

import { removeTokenFromLocalStorage } from './token';

/**
 * Take a LOGOUT_REQUEST and try to call the
 * endpoint
 * @yield {[type]} [description]
 */
export default function* logoutFlow() {
  yield takeLatest(logout.SUCCESS, removeTokenAndRedirect);
}

export function* removeTokenAndRedirect() {
  yield call(removeTokenFromLocalStorage);
  yield put(push('/login'));
}
