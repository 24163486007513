import { defaultTheme, ThemeProvider as EGThemeProvider } from 'evergreen-ui';
import { decamelize } from 'humps';
import { flatMap, isNil, isString, omit, reduce } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import styled, {
  css,
  keyframes as styledKeyframes,
  ThemeProvider as SCThemeProvider,
} from 'styled-components';
import { get, system } from 'styled-system';

export { default as themed } from '@styled-system/css';
export { createGlobalStyle, css } from 'styled-components';
export {
  alignContent,
  alignItems,
  alignSelf,
  background,
  borderColor,
  borderRadius,
  borders,
  bottom,
  boxShadow,
  buttonStyle,
  color,
  colorStyle,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexWrap,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  grid,
  height,
  justifyContent,
  justifyItems,
  justifySelf,
  left,
  letterSpacing,
  lineHeight,
  margin,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  opacity,
  order,
  overflow,
  overflowX,
  overflowY,
  padding,
  position,
  right,
  size,
  space,
  style,
  system,
  textAlign,
  textStyle,
  top,
  typography,
  variant,
  verticalAlign,
  width,
  zIndex,
} from 'styled-system';

export function ThemeProvider({ theme, evergreenTheme, children }) {
  return (
    <EGThemeProvider value={evergreenTheme || defaultTheme}>
      <SCThemeProvider theme={theme}>{children}</SCThemeProvider>
    </EGThemeProvider>
  );
}

export const variantTransform = ({ key, prop = 'variant', transformFn }) => {
  const fn = system({
    [prop]: (val, scale, props) => {
      if (isNil(val)) return {};

      return transformFn(get(props.theme, [key, val].join('.')));
    },
  });

  return fn;
};

export const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
  },
});

export const cursor = system({
  cursor: true,
});

export const intent = (key) => {
  const fn = system({
    intent: (value, scale, props) => {
      const { intent = 'none', appearance = 'default' } = props;
      const { appearances, ...baseIntentStyle } =
        get(props.theme, [key, 'intents', intent].join('.')) || {};
      const intentAppearanceStyle = get(appearances, appearance) || {};
      return baseIntentStyle
        ? { ...baseIntentStyle, ...intentAppearanceStyle }
        : {};
    },
    appearance: true,
  });

  fn.propTypes = {
    intent: PropTypes.oneOf(['none', 'success', 'danger', 'warning']),
    appearance: PropTypes.oneOf(['default', 'minimal', 'primary']),
  };

  return fn;
};

export function truncate(width) {
  function truncateWidth(props) {
    return (
      (props.truncateWidth || width) && {
        maxWidth: `${props.truncateWidth || width}`,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    );
  }

  truncateWidth.propTypes = {
    truncateWidth: PropTypes.string,
  };

  return truncateWidth;
}

const expanded = {
  m: 'margin',
  mt: 'marginTop',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mr: 'marginRight',
  mx: 'marginX',
  my: 'marginY',
  p: 'padding',
  pt: 'paddingTop',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  pr: 'paddingRight',
  px: 'paddingX',
  py: 'paddingY',
};
export const transformStylePropsForEvergreen = (props) =>
  Object.keys(expanded).reduce(
    (memo, key) => ({
      ...memo,
      // if we have been passed the shorthand, but not the long hand
      ...(key in expanded && key in props && !(expanded[key] in props)
        ? { [expanded[key]]: props[key] }
        : {}),
    }),
    omit(Object.keys(expanded), props)
  );

export function keyframes(name, styles) {
  return styledKeyframes(styles);
}

const omitBlacklist = (blacklist, props) => {
  // Allows us to test styles based on data-* attributes, instead of having
  // to check the stylesheet.
  if (import.meta.env.MODE === 'test') {
    const styleProps = reduce(
      (acc, prop) => {
        if (prop in props) {
          const decamed = decamelize(prop, { separator: '-' });
          acc[`style-${decamed}`] = String(props[prop]);
        }

        return acc;
      },
      {},
      blacklist
    );

    return {
      ...omit(blacklist, props),
      ...styleProps,
    };
  }

  return omit(blacklist, props);
};

const onHover = (styles = []) => {
  return (props) =>
    props['hoverProps'] &&
    css`
      &:hover {
        ${styles.map(
          (styleFn) => (props) =>
            styleFn({ ...props, ...props['hoverProps'], isHover: true })
        )};
      }
    `;
};

const getSystemPropNames = flatMap('propNames');

export const createStyledComponent =
  (is = 'div', defaultProps = {}) =>
  (...styles) => {
    const Base = !isString(is)
      ? is
      : // eslint-disable-next-line react/display-name
        React.forwardRef(({ is: Tag = is, blacklist, ...props }, ref) => (
          <Tag ref={ref} {...omitBlacklist(blacklist, props)} />
        ));

    const Component = styled(Base)(
      ...styles,
      (props) => props.css,
      onHover(styles)
    );
    const baseProps = Base.defaultProps || {};
    const systemPropNames = getSystemPropNames(styles);

    // Need to use `_foldedDefaultProps` here instead of straight `defaultProps`
    //
    // Assigning to `defaultProps` on a styled-component can trigger an infinite
    // recursion due to SC overriding `defaultProps` with a getter/setter that
    // tries to merge all objects in the `defaultProps`.
    Component._foldedDefaultProps = {
      ...baseProps,
      ...defaultProps,
      blacklist: [
        'hoverProps',
        'css',
        '_css',
        ...(systemPropNames || []),
        ...(baseProps.blacklist || []),
        ...(defaultProps.blacklist || []),
      ],
    };

    return Component;
  };

export default createStyledComponent;
