import { combineReducers } from 'redux';

import { Types } from './constants';
import errorReducer from './reducers/error';
import errorMessageReducer from './reducers/errorMessage';
import pageReducer from './reducers/page';
import resultsReducer from './reducers/results';
import statusReducer from './reducers/status';

const reducer = combineReducers({
  status: statusReducer,
  pagination: pageReducer,
  error: errorReducer,
  errorMessage: errorMessageReducer,
  results: resultsReducer,
});

export default () =>
  (state = {}, action) => {
    const { type, meta } = action;
    const [, statusType] = type.split('/');
    const isApiStatus = statusType in Types;

    if (!meta || !meta.ref || !isApiStatus) {
      return reducer(state, action);
    }

    return reducer(state, {
      ...action,
      type: statusType,
    });
  };
