import { TagInput as EvergreenTagInput } from 'evergreen-ui';
import React, { forwardRef } from 'react';

const TAG_PROPS = {
  role: 'checkbox',
  'aria-checked': true,
};

export default forwardRef(function TagInput(props, ref) {
  return <EvergreenTagInput ref={ref} {...props} tagProps={TAG_PROPS} />;
});
