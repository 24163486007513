/**
 * Input tag
 */
import React from 'react';

import Text from '~/components/Text';
import styled, {
  background,
  borderColor,
  borderRadius,
  borders,
  boxShadow,
  themed,
  variant,
} from '~/lib/styled';

import LabelledInput from '../LabelledInput';

const StyledInput = styled(Text, {
  is: 'input',
  variant: 'default',
})(
  themed((p) => ({
    boxSizing: 'border-box',

    '&:disabled': {
      cursor: 'not-allowed',
    },

    '&[type="file"]:not([data-empty])': {
      borderColor: 'green.base',
    },
  })),
  background,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  variant({
    variants: {
      thin: {
        width: '3em',
        paddingLeft: '0.5em',
        paddingRight: '0.5em',
        textAlign: 'center',
      },
    },
  })
);

const Input = React.forwardRef(function Input(
  { field = {}, form, label, description, footer, ...props },
  ref
) {
  const isEmpty =
    props.type === 'file'
      ? !(field.value instanceof File)
      : props.value === '' || !props.value;
  const input = (
    <StyledInput
      {...field}
      {...(form && form.errors[field.name] ? { 'data-invalid': true } : {})}
      {...(isEmpty ? { 'data-empty': true } : {})}
      {...props}
      ref={ref}
    />
  );

  if (label) {
    return (
      <LabelledInput text={label} description={description} footer={footer}>
        {input}
      </LabelledInput>
    );
  }

  return input;
});

Input.defaultProps = StyledInput.defaultProps;

export default Input;
