import PropTypes from 'prop-types';
import React from 'react';

import Icon from '~/components/Icon';

const HeaderIcon = ({ error, ...props }) => (
  <Icon mt={4} color={error ? 'danger' : 'success'} {...props} />
);

HeaderIcon.propTypes = {
  ...Icon.propTypes,
  error: PropTypes.bool,
};

export default HeaderIcon;
