import { handleActions } from 'redux-actions';

import { Types } from '../constants';
import slice from '../helpers/sliceReducer';

export default slice(
  handleActions(
    {
      [Types.REQUEST]: () => '',
      [Types.FAILURE]: (state, { payload }) => {
        if (payload.fullErrorMessages) {
          return payload.fullErrorMessages.join(', ');
        }
        return payload.message;
      },
    },
    ''
  )
);
