import React from 'react';

import Svg from './Svg';

export default function MyobIcon(props) {
  return (
    <Svg viewBox="0 0 180 35" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Payrix Logo</title>
      <g clipPath="url(#clip0)">
        <path
          d="M10.1629 31.1483H5.66292C2.52809 31.1483 0 28.6159 0 25.4758V5.67254C0 2.53238 2.52809 0 5.66292 0H10.1629V3.1908H5.66292C4.29775 3.1908 3.18539 4.30505 3.18539 5.67254V25.4758C3.18539 26.8433 4.29775 27.9575 5.66292 27.9575H10.1629V31.1483Z"
          fill="#C351E8"
        ></path>
        <path
          d="M43.0791 31.1483H38.5791V27.9575H43.0791C44.4443 27.9575 45.5566 26.8433 45.5566 25.4758V5.67254C45.5566 4.30505 44.4443 3.1908 43.0791 3.1908H38.5791V0H43.0791C46.2139 0 48.742 2.53238 48.742 5.67254V25.4758C48.742 28.6159 46.1634 31.1483 43.0791 31.1483Z"
          fill="#C351E8"
        ></path>
        <path
          d="M71.4944 3.19092H64.3652V27.9576H67.4495V18.993L71.4439 19.0436C74.1237 19.0436 76.2473 18.2839 77.7135 16.8151C79.1798 15.3464 79.9888 13.4218 79.9888 11.1426C79.9888 8.86346 79.2304 6.93885 77.7135 5.41942C76.1967 3.89999 74.1742 3.19092 71.4944 3.19092ZM75.6405 14.7892C74.7304 15.6502 73.4664 16.0554 71.8484 16.0554H67.5001V6.12848H71.8484C73.4664 6.12848 74.7304 6.58431 75.6405 7.49597C76.5001 8.40763 76.9551 9.62317 76.9551 11.092C76.9551 12.662 76.5001 13.9282 75.6405 14.7892Z"
          fill="#232122"
        ></path>
        <path
          d="M97.8368 25.0198C97.5839 24.7666 97.4828 24.2601 97.4828 23.4497V16.511C97.4828 14.5864 96.8761 13.0163 95.6626 11.8514C94.4491 10.6865 92.8311 10.1294 90.8592 10.1294C88.9884 10.1294 87.3705 10.6865 86.0558 11.8008C84.6907 12.915 84.0334 14.2825 84.0334 15.8019V16.511L86.966 16.4604H87.1682V16.2578C87.2188 15.1435 87.5221 14.3332 88.1794 13.776C88.8367 13.1683 89.6963 12.8644 90.8592 12.8644C91.9716 12.8644 92.8817 13.1683 93.539 13.8267C94.1963 14.4344 94.4997 15.3461 94.4997 16.4604V17.0175L89.6963 17.6253C87.6738 17.8785 86.1064 18.4863 84.9435 19.3979C83.7806 20.3602 83.1738 21.6264 83.1738 23.1965C83.1738 24.6653 83.73 25.9315 84.7918 26.9444C85.9042 27.9067 87.3199 28.4132 89.2412 28.4132C91.6682 28.4132 93.539 27.5522 94.9042 25.8302C95.0559 26.438 95.4098 26.9444 95.8648 27.3496C96.4716 27.8561 97.2806 28.16 98.1907 28.16C98.9491 28.16 99.657 28.0587 100.314 27.9067L100.466 27.8561V25.425H99.1514C98.4941 25.3744 98.039 25.2224 97.8368 25.0198ZM89.3424 25.6782C88.3311 25.6782 87.5221 25.4756 87.0165 25.0198C86.5109 24.564 86.2581 24.0069 86.2581 23.2471L86.2075 23.1459C86.2075 22.4368 86.5109 21.829 87.0671 21.3732C87.6233 20.8667 88.5334 20.5628 89.5952 20.3602L94.4997 19.7018V20.8667C94.4997 22.2848 93.9941 23.4497 93.0334 24.3614C92.0727 25.2224 90.8087 25.6782 89.3424 25.6782Z"
          fill="#232122"
        ></path>
        <path
          d="M110.933 24.2603L105.573 10.6361L105.523 10.4841H102.186L109.315 27.9069L108.405 30.1861C108.202 30.6926 107.899 31.0977 107.596 31.351C106.938 31.8068 105.573 31.7055 104.107 31.6549H103.601V34.7444L106.584 34.8457C107.747 34.8457 108.708 34.5418 109.466 33.934C110.174 33.3769 110.832 32.4652 111.337 31.1484L119.478 10.788L119.579 10.4841H116.242L110.933 24.2603Z"
          fill="#232122"
        ></path>
        <path
          d="M132.169 10.1294C131.056 10.1294 129.994 10.4839 129.034 11.0917C128.376 11.5475 127.77 12.054 127.416 12.7124V10.5346H124.534V28.008H127.567V19.9044C127.567 17.8278 127.921 16.1565 128.73 14.8903C129.489 13.6241 130.551 13.0163 131.966 13.0163C133.028 13.0163 133.837 13.4215 134.545 14.2825C135.253 15.1435 135.556 16.3084 135.556 17.8278V18.8408L138.539 18.7901V17.7265C138.539 15.3968 137.983 13.5228 136.82 12.2059C135.607 10.7878 134.09 10.1294 132.169 10.1294Z"
          fill="#232122"
        ></path>
        <path
          d="M150.776 8.00226C151.635 8.00226 152.343 7.69837 152.95 7.0906C153.557 6.48283 153.86 5.77376 153.86 4.91275C153.86 4.05174 153.557 3.34267 152.95 2.7349C152.394 2.12713 151.635 1.82324 150.776 1.82324C149.916 1.82324 149.208 2.12713 148.602 2.7349C147.995 3.34267 147.691 4.05174 147.691 4.91275C147.691 5.77376 147.995 6.48283 148.602 7.0906C149.208 7.69837 149.916 8.00226 150.776 8.00226Z"
          fill="#232122"
        ></path>
        <path
          d="M152.545 10.5347H142.938V13.3203H149.562V25.2225H142.989V28.0081H159.017V25.2225H152.545V10.5347Z"
          fill="#232122"
        ></path>
        <path
          d="M173.426 18.9423L179.544 10.4841H175.954L171.353 16.9164L166.803 10.5854L166.752 10.4841H163.112L169.23 18.9423L162.657 28.0082H166.247L171.404 21.0188L176.359 27.9069L176.41 28.0082H179.999L173.426 18.9423Z"
          fill="#232122"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="180" height="34.8456" fill="white"></rect>
        </clipPath>
      </defs>
    </Svg>
  );
}
