import PropTypes from 'prop-types';
import React from 'react';

import Icon from '~/components/Icon';

import Button from './Button';

const { fontSize, px, ...ButtonDefaultProps } = Button.defaultProps;

const getIconSizeFromButtonSize = (size) => {
  if (size <= 28) return 12;
  if (size <= 34) return 14;
  if (size <= 40) return 16;
  if (size <= 48) return 18;

  return 20;
};

const IconButton = React.forwardRef(function IconButton(props, ref) {
  const { icon, iconSize, iconCircle, size, ...rest } = props;

  return (
    <Button px={0} size={size} {...rest} ref={ref}>
      <Icon
        name={icon}
        size={iconSize || getIconSizeFromButtonSize(size)}
        circle={iconCircle}
        color="currentColor"
      />
    </Button>
  );
});

IconButton.propTypes = {
  // Composes button
  ...Button.propTypes,

  // Icon name
  icon: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  ...ButtonDefaultProps,
  size: 34,
};

export default IconButton;
