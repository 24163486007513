import React from 'react';

import Text from '~/components/Text';
import styled, { color, truncate, variant } from '~/lib/styled';

export const StyledHeading = styled(Text, {
  is: 'h2',
  size: '500',
})(
  variant({
    prop: 'size',
    variants: {
      900: {
        fontSize: '35px',
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '-0.2px',
        fontFamily: 'display',
        color: 'neutral.dark',
      },
      800: {
        fontSize: '29px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '-0.2px',
        fontFamily: 'display',
        color: 'neutral.dark',
      },
      700: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '-0.07px',
        fontFamily: 'display',
        color: 'neutral.dark',
      },
      600: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '-0.07px',
        fontFamily: 'display',
        color: 'neutral.dark',
      },
      500: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '-0.05px',
        fontFamily: 'ui',
        color: 'neutral.dark',
      },
      400: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '-0.05px',
        fontFamily: 'ui',
        color: 'neutral.dark',
      },
      300: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0',
        color: 'neutral.dark',
      },
      200: {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        color: 'neutral.N7',
      },
      100: {
        fontSize: '11px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0.6px',
        textTransform: 'uppercase',
        color: 'neutral.N8',
      },
      copy: {
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '16px',
        // letterSpacing: '0',
        color: 'neutral.dark',
      },
    },
  }),
  color,
  truncate()
);

StyledHeading.defaultProps = {
  ...StyledHeading.defaultProps,
  blacklist: [...StyledHeading.defaultProps.blacklist, 'size'],
};

StyledHeading.displayName = 'Heading';

const Heading = React.forwardRef(function Heading(props, ref) {
  const { size = StyledHeading.defaultProps.size } = props;
  const is = getElementForSize(size);

  return <StyledHeading ref={ref} is={is} {...props} />;
});

Heading.defaultProps = StyledHeading.defaultProps;

export default Heading;

function getElementForSize(size) {
  if (size < 200) {
    return 'h6';
  } else if (size < 400) {
    return 'h5';
  } else if (size < 500) {
    return 'h4';
  } else if (size < 700) {
    return 'h3';
  } else {
    return 'h2';
  }
}
