import { denormalize, normalize, schema } from 'normalizr';

export { denormalize, normalize };
export const Entity = schema.Entity;

export const user = new schema.Entity('users');
export const accessToken = new schema.Entity('accessTokens');
export const lastImport = new schema.Entity('imports');
export const dashboard = new schema.Entity('dashboards');
export const invoice = new schema.Entity('invoices');
export const creditNote = new schema.Entity('creditNotes');
export const reminder = new schema.Entity('reminders');
export const collector = new schema.Entity('collectors');
export const collection = new schema.Entity('collections');
export const contact = new schema.Entity('contacts');
export const activity = new schema.Entity('activities');
export const note = new schema.Entity('notes');
export const phoneCall = new schema.Entity('phoneCalls');
export const subscription = new schema.Entity('subscriptions');
export const report = new schema.Entity('reports');
export const listFilter = new schema.Entity('listFilters');
export const integration = new schema.Entity('integrations');
export const job = new schema.Entity('jobs');

export const users = [user];
export const accessTokens = [accessToken];
export const invoices = [invoice];
export const creditNotes = [creditNote];
export const reminders = [reminder];
export const collectors = [collector];
export const collections = [collection];
export const contacts = [contact];
export const activities = [activity];
export const notes = [note];
export const phoneCalls = [phoneCall];
export const subscriptions = [subscription];
export const reports = [report];
export const listFilters = [listFilter];
export const integrations = [integration];
export const jobs = [job];

// References
collector.define({
  reminders: [reminder],
});
collection.define({
  nextReminder: reminder,
  nextCollection: collection,
  invoices,
  contact,
  collector,
});
contact.define({
  collections,
  invoices,
  creditNotes,
  collector,
});
invoice.define({ contact, collections });
note.define({ user, activity });

// The mother org
export const organisation = new schema.Entity('organisations', {
  owner: user,
  lastImport,
  users,
  invoices,
  creditNotes,
  collectors,
  collections,
  contacts,
  dashboard,
  subscription,
  reports,
  listFilters,
});
export const organisations = [organisation];
