import { Switch as EvergreenSwitch } from 'evergreen-ui';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Text from '~/components/Text';

const va = { verticalAlign: 'text-bottom' };

function Switch({ label, name, ...props }) {
  return (
    <label htmlFor={name}>
      <Field type="checkbox" name={name}>
        {({ field }) => (
          <EvergreenSwitch
            {...field}
            value={String(field.value)}
            is="div"
            name={name}
            id={name}
            display="inline-block"
            marginRight={4}
            {...props}
          />
        )}
      </Field>
      <Text display="inline-block" css={va}>
        {label}
      </Text>
    </label>
  );
}

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Switch;
