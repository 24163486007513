import ErrorReporter from './errorReporter';
import SessionRecorder from './sessionRecorder';

export { createErrorReporterEnhancer } from './errorReporter';
export { createRecordingMiddleware } from './sessionRecorder';

const URL_BLACKLIST = /^\/public\/client\/invoices\//;

export function identify(...args) {
  ErrorReporter.identify(...args);
  SessionRecorder.identify(...args);
}

export default function setup(env, location) {
  if (env) {
    ErrorReporter.configure({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: __APP_VERSION__,
      environment: env,
      whitelistUrls: [
        /^https?:\/\/(.*\.)?debtordaddy\.com/,
        /^https?:\/\/(.*\.)?creditorwatchcollect\.com\.au/,
      ],
    });

    SessionRecorder.setup({
      location,
      dsn: 'debtor-daddy',
      environment: env,
      blacklistUrls: [URL_BLACKLIST],
    });

    ErrorReporter.setSessionUrlCallback(() => {
      return SessionRecorder.getRecordingUrl();
    });
  }
}
