import { pick } from 'lodash/fp';
import { createSelector } from 'reselect';

import { SET_CURRENT_ORGANISATION_ID } from '~/actions/currentOrganisation';

const filterStaleData = pick([
  'router',
  'language',
  'auth',
  'api',
  'currentUser',
  'currentOrganisation',
]);

/**
 * Listens for an org event to reset the data
 * @param  {[type]} reducer [description]
 * @return {[type]}         [description]
 */
function createRootReducer(reducer) {
  return (state, action) => {
    if (action.type === SET_CURRENT_ORGANISATION_ID) {
      return reducer(filterStaleData(state), action);
    }

    return reducer(state, action);
  };
}

export default createRootReducer;

const selectRouter = (state) => state.router;

export const makeSelectLocation = () =>
  createSelector(selectRouter, (route) => route.location);
