import React from 'react';

import Text from '~/components/Text';

export default (props) => (
  <Text
    is="article"
    textAlign="center"
    mx={'auto'}
    maxWidth="30em"
    {...props}
  />
);
