import { isSameDay, parseISO } from 'date-fns';
import React from 'react';

import Icon from '~/components/Icon';
import { FormattedDate, FormattedTime } from '~/components/Intl';
import { ExternalLink } from '~/components/Link';
import Pane from '~/components/Pane';
import Paragraph from '~/components/Paragraph';
import styled, { intent } from '~/lib/styled';

const StatusBannerPane = styled(Pane, { p: 2 })(intent('statusBanner'));

const StatusBannerStateless = ({
  title,
  link,
  scheduledFor,
  scheduledUntil,
  intent,
  appearance,
}) => {
  const dateParts = isDifferentDay(scheduledFor, scheduledUntil)
    ? { day: '2-digit', month: 'short' }
    : {};
  const schedule =
    scheduledFor && scheduledUntil ? (
      <>
        {' '}
        from <FormattedTime hour12={true} value={scheduledFor} /> &ndash;{' '}
        <FormattedDate
          value={scheduledUntil}
          {...dateParts}
          hour12
          hour="numeric"
          minute="numeric"
        />
      </>
    ) : null;
  return (
    <StatusBannerPane intent={intent} appearance={appearance} p={3}>
      <Paragraph textAlign="center">
        <Icon
          name={intent === 'danger' ? 'warning-sign' : 'filled-setting-gear'}
          mr={3}
        />
        {title}
        {schedule}, view our{' '}
        <ExternalLink href={link}>status page</ExternalLink> for more info and
        updates.
      </Paragraph>
    </StatusBannerPane>
  );
};

export default StatusBannerStateless;

function isDifferentDay(date1, date2) {
  if (date1 === date2) return false;

  return !isSameDay(parseISO(date1), parseISO(date2));
}
