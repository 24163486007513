import { ContentState, EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

import Popover from '~/components/Popover';
import Text from '~/components/Text';
import styled, { background, borderRadius, cursor } from '~/lib/styled';
import ErrorReporter from '~/utils/errorReporter';

import applyPlaceholder from './applyPlaceholder';
import isFocused from './isFocused';
import PlaceholderDialog from './PlaceholderDialog';
import setSelectionToEntity from './setSelectionToEntity';

const PlaceholderElement = styled(Text, {
  is: 'span',
  cursor: 'pointer',
  fontFamily: 'mono',
  fontSize: '80%',
  borderRadius: 1,
  p: '2px',
})(background, cursor, borderRadius);

export default class PlaceholderComponent extends React.Component {
  static propTypes = {
    decoratedText: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    entityKey: PropTypes.string.isRequired,
    offsetKey: PropTypes.string.isRequired,
    contentState: PropTypes.instanceOf(ContentState).isRequired,
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    store: PropTypes.object,
  };

  state = {
    open: false,
  };

  constructor(props) {
    super(props);

    this.validPlaceholders = props.store
      .getProps()
      .placeholders.reduce((memo, p) => {
        return [...memo, ...p.items.map((i) => i.value)];
      }, []);
  }

  componentDidCatch(e) {
    ErrorReporter.report(e);
  }

  getEntity = () => {
    const { offsetKey, blockKey, entityKey } = this.props;

    return {
      entityKey,
      blockKey,
      offsetKey,
    };
  };

  getEntityData = () => {
    const { contentState, entityKey } = this.props;
    const { placeholder, fallback } = contentState
      .getEntity(entityKey)
      .getData();

    return { placeholder, fallback };
  };

  handleOpenDialog = () => {
    if (!isFocused(this.props.getEditorState, this.state)) {
      this.setState({ open: true });
      this.props.store.setReadOnly(true);
      setSelectionToEntity(
        this.props.getEditorState,
        this.props.setEditorState,
        this.getEntity()
      );
    }
  };

  handleConfirm = (_placeholder, fallback) => {
    // We only allow updating the `fallback` through this dialog so we grab
    // the existing `placeholder`
    const { placeholder } = this.getEntityData();
    this.setState({ open: false });

    const editorState = applyPlaceholder(
      this.props.getEditorState(),
      placeholder,
      fallback
    );

    this.props.setEditorState(editorState);
    this.props.store.setReadOnly(false);
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.store.setReadOnly(false);

    // Deselect placeholder and put selection to end of
    // the placeholder text
    this.setSelectionToEndOfPlaceholder();
  };

  // Deselect placeholder and put selection to end of the placeholder text
  setSelectionToEndOfPlaceholder() {
    const { getEditorState, setEditorState } = this.props;
    const editorState = getEditorState();
    const selection = editorState.getSelection();
    setEditorState(
      EditorState.forceSelection(
        editorState,
        selection.set('anchorOffset', selection.focusOffset)
      )
    );
  }

  render() {
    const { children, offsetKey } = this.props;
    const { placeholder, fallback } = this.getEntityData();
    const isValid = this.validPlaceholders.includes(placeholder);
    const inner = (
      <PlaceholderElement
        data-offset-key={offsetKey}
        color={isValid ? 'neutral.base' : 'red.base'}
        bg={isValid ? 'neutral.N3A' : 'red.lightest'}
        hoverProps={{ bg: isValid ? 'neutral.light' : 'red.light' }}
      >
        {children}
      </PlaceholderElement>
    );

    if (!isValid) {
      return inner;
    }

    return (
      <Popover
        onOpen={this.handleOpenDialog}
        onCloseComplete={this.handleClose}
        content={({ close }) => (
          <PlaceholderDialog
            close={(...args) => {
              this.handleClose(...args);
              close();
            }}
            confirm={(...args) => {
              this.handleConfirm(...args);
              close();
            }}
            fallback={fallback}
            hidePlaceholders
          />
        )}
      >
        {inner}
      </Popover>
    );
  }
}
