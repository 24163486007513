import invariant from 'invariant';
import { every, isString } from 'lodash';
import { createAction } from 'redux-actions';

// Creates an action returning a promise for when it's resolved
export function createPromiseAction(
  requestType,
  successType,
  failureType,
  ...args
) {
  invariant(
    every([requestType, successType, failureType], isString),
    '(lib/promise) createPromiseAction: expects three types as the first arguments and they should all be an action type'
  );

  const actionCreator = createAction(requestType, ...args);

  return (payload, ...rest) => {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    // Add a global error handler so we don't raise for rejected promises
    // NOTE: the api actions also use the `createPromiseAction` as a
    // convienience. The promise / rejection flow within api/saga.js still
    // works however because it is a separate promise created for the
    // `request` that is "wrapped" by the promise created for this action.
    promise.catch((error) => ({ error }));

    const action = actionCreator(payload, ...rest);

    return {
      ...action,
      meta: {
        ...action.meta,
        defer: { resolve, reject },
        then: (...args) => promise.then(...args),
        types: [successType, failureType],
      },
    };
  };
}

export default createPromiseAction;
