/**
 * The function returns the range of given entity inside the block.
 * {
 *   start: undefined,
 *   end: undefined,
 *   text: undefined,
 * }
 */
export default function getEntityRange(editorState, entityKey) {
  const selection = editorState.getSelection();
  const selectionKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const block = currentContent.getBlockForKey(selectionKey);
  let entityRange;
  block.findEntityRanges(
    (value) => value.get('entity') === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get('text').slice(start, end),
      };
    }
  );
  return entityRange;
}
