import Box from '~/components/Box';
import styled, {
  alignItems,
  flexDirection,
  flexWrap,
  gap,
  justifyContent,
} from '~/lib/styled';

export const Flex = styled(Box)(
  () => ({
    display: 'flex',
  }),
  gap,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
);

export default Flex;
