import styled, {
  alignSelf,
  bottom,
  color,
  display,
  flex,
  flexBasis,
  fontSize,
  height,
  left,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  order,
  overflow,
  position,
  right,
  space,
  top,
  width,
  zIndex,
} from '~/lib/styled';

const Box = styled()(
  space,
  width,
  height,
  display,
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  fontSize,
  color,
  flex,
  flexBasis,
  order,
  overflow,
  alignSelf,
  position,
  zIndex,
  top,
  right,
  bottom,
  left
);

Box.displayName = 'Box';

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...display.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...flex.propTypes,
  ...flexBasis.propTypes,
  ...order.propTypes,
  ...overflow.propTypes,
  ...alignSelf.propTypes,
  ...position.propTypes,
  ...zIndex.propTypes,
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
};

export default Box;
