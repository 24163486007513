import { Alert as BaseAlert } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '~/components/Box';

export const Alert = ({
  children,
  intent,
  title,
  appearance,
  isRemoveable,
  onRemove,
  hasIcon,
  ...props
}) => (
  <Box {...props}>
    <BaseAlert
      intent={intent}
      title={title}
      appearance={appearance}
      isRemoveable={isRemoveable}
      onRemove={onRemove}
      hasIcon={hasIcon}
    >
      {children}
    </BaseAlert>
  </Box>
);

Alert.propTypes = {
  ...BaseAlert.propTypes,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

export default Alert;
