import { handleActions } from 'redux-actions';

import { Types } from '../constants';
import slice from '../helpers/sliceReducer';

export default slice(
  handleActions(
    {
      [Types.REQUEST]: () => false,
      [Types.FAILURE]: () => true,
    },
    false
  )
);
