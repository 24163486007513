import { Popover, Position } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Box from '~/components/Box';
import styled, { themed } from '~/lib/styled';

export { Position };

export default Popover;

/* ===== PopoverGroup ===== */

export const PopoverGroup = styled(
  Box,
  {}
)(
  themed(() => ({
    minWidth: '180px',

    '&:not(:last-child)': {
      borderBottom: 'default',
    },
  }))
);

/* ===== PopoverLink ===== */

export const PopoverLink = styled(({ href, rel, ...props }) => {
  const attrs = {
    rel,
  };

  if (href) {
    // Don't pass hash links to react-router because it navigates to the root (/)
    if (rel === 'external' || href.startsWith('#')) {
      attrs.href = href;
    } else {
      attrs.to = href;
    }

    return <Link {...props} {...attrs} />;
  }

  return <button {...props} {...attrs} />;
})((p) => ({
  boxSizing: 'border-box',
  display: 'block',
  width: '100%',
  padding: '0.7em 0.75em',
  border: 'none',
  background: 'none',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  fontSize: '0.875em',
  cursor: 'pointer',
  '&[disabled]': {
    cursor: 'not-allowed',
    opacity: '0.8',
    backgroundImage: 'none',
    color: p.theme.colors.textMuted,
  },
}));

PopoverLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  rel: PropTypes.string,
};

/* ===== PopoverListLink ===== */

export const PopoverListLink = styled(PopoverLink)((p) => ({
  width: 'calc(100% - 0.4em)',
  margin: '0 0.2em',
  borderRadius: p.theme.radii[1],

  '&:first-child': {
    marginTop: '0.2em',
  },

  '&:last-child': {
    marginBottom: '0.2em',
  },

  '&:hover, &:focus': {
    outline: 'none',
    background: p.theme.colors.blue.lightest,
    color: p.theme.colors.blue.base,
  },
}));

/* ===== PopoverCtaLink ===== */

export const PopoverCtaLink = styled(PopoverLink, { color: 'primary.base' })(
  (p) => ({
    textAlign: 'center',
    textTransform: 'capitalize',

    '&:hover, &:focus': {
      outline: 'none',
      background: p.theme.neutral.lightest,
    },

    '&:active': {
      background: p.theme.neutral.lightest,
    },
  })
);
