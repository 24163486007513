import { get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { passwordReset } from '~/lib/api';

// ------------------------------------
// Reducer
// ------------------------------------
const getTokenError = get('errors.reset_password_token[0]');

const initialState = {
  tokenErrorMessage: '',
  isReset: false,
};
const passwordResetReducer = handleActions(
  {
    [passwordReset.SUCCESS]: (state) => ({
      ...state,
      isReset: true,
    }),
    [passwordReset.FAILURE]: (state, action) => ({
      ...state,
      tokenErrorMessage: getTokenError(action.payload),
    }),
  },
  initialState
);

export const key = 'ui.resetPassword.reset';

export default passwordResetReducer;

// ------------------------------------
// Selectors
// ------------------------------------

const selectResetPassword = get(key);

export const selectResetSuccess = createSelector(
  selectResetPassword,
  (resetPassword) => resetPassword.isReset
);

export const selectResetTokenError = createSelector(
  selectResetPassword,
  (reset) => reset.tokenErrorMessage
);
