import 'whatwg-fetch';

import { camelize } from 'humps';
import invariant from 'invariant';
import { isEmpty, isObject } from 'lodash';
import { normalize } from 'normalizr';
import uri from 'uri-templates';

import { API_HEADERS, API_ROOT } from './constants';
import checkResponse from './middleware/checkResponse';
import includeCredentials from './middleware/includeCredentials';
import normalizeResponse from './middleware/normalizeResponse';
import parameterizeUrl from './middleware/parameterizeUrl';
import stringifyBody from './middleware/stringifyBody';
import stripEmptyBody from './middleware/stripEmptyBody';
import { snakeCaseBodyKeys } from './middleware/transformKeys';

export const HEAD = 'HEAD';
export const GET = 'GET';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const POST = 'POST';
export const DELETE = 'DELETE';

const requestMiddleware = applyMiddleware([
  snakeCaseBodyKeys,
  parameterizeUrl,
  stripEmptyBody,
  stringifyBody,
  includeCredentials,
]);

export function request(method, endpoint, schema) {
  // We handle the params variable separately, so we have more control over the
  // handling of array values. See `parameterizeUrl` middleware
  const requestUrl = uri(endpoint.replace('{?params*}', '{+params}'));
  const description = `${method} ${requestUrl}`;
  const normalizer = (response) => {
    const normalizedBody = schema
      ? normalize(response.body || {}, schema)
      : response.body;

    if (isObject(normalizedBody)) {
      // Defines the raw body as non-enumerable on the object.
      // This is because all consumers of `action.meta.then` expect the action.payload
      // as the response. A better solution would be to change the `then`'s
      // resolved value to be the full action, and include `raw` in the meta.
      Object.defineProperty(normalizedBody, 'raw', {
        enumerable: false,
        writable: false,
        value: response.body,
      });
    }

    return {
      ...response,
      body: normalizedBody,
    };
  };

  const requestCreator = (params = {}, passedHeaders = {}) => {
    const headers = getHeaders(passedHeaders);

    const { url, ...req } = requestMiddleware({
      url: requestUrl,
      method,
      headers,
      body: params,
    });

    // Reset body if the params are some sort of FormData object and add the
    // special `_method` attribute to indicate this is a 'put' request
    if (params?.formData instanceof FormData) {
      params.formData.append('_method', req.method.toLowerCase());
      req.method = POST;
      req.body = params.formData;
      // Remove content type to allow FormData to set with boundary
      delete req.headers['Content-Type'];
    }

    invariant(
      ![GET, HEAD].includes(method) || isEmpty(req.body),
      `Requesting url ${url}. Fetch method [${method}] ` +
        `cannot have a request body: ${req.body}`
    );

    return fetch(API_ROOT + url, req)
      .then(normalizeResponse)
      .then(checkResponse)
      .then(transformResponse)
      .then(normalizer);
  };

  requestCreator.method = method;
  requestCreator.schema = schema;
  requestCreator.url = endpoint;
  requestCreator.urlTemplate = requestUrl;
  requestCreator.toString = () => description;

  return requestCreator;
}

function applyMiddleware(middlewares) {
  return function _applyMiddleware(payload) {
    return middlewares.reduce((pay, middleware) => middleware(pay), payload);
  };
}

function transformResponse(response) {
  const headers = Array.from(response.headers).reduce(mapHeadersToObject, {});

  return {
    body: response.resolvedBody,
    headers,
  };
}

function mapHeadersToObject(obj, currentValue) {
  return {
    ...obj,
    [camelize(currentValue[0])]: currentValue[1],
  };
}

function getHeaders(headers) {
  return {
    ...API_HEADERS,
    ...headers,
  };
}

export default request;
