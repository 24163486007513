import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './sagaInjectors';

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default (sagas) => (WrappedComponent) => {
  class SagasInjector extends React.Component {
    static WrappedComponent = WrappedComponent;

    static contextType = ReactReduxContext;

    static displayName = `withSaga(${sagas.map((r) => r.key)})`;

    constructor(props, context) {
      super(props, context);

      this.injectors = getInjectors(context.store);

      sagas.forEach(({ key, saga, mode }) => {
        this.injectors.injectSaga(key, { saga, mode });
      });
    }

    componentWillUnmount() {
      sagas.forEach(({ key }) => {
        this.injectors.ejectSaga(key);
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return hoistNonReactStatics(SagasInjector, WrappedComponent);
};

export function useInjectSagas(sagas) {
  const context = React.useContext(ReactReduxContext);

  React.useEffect(() => {
    const injectors = getInjectors(context.store);

    sagas.forEach(({ key, saga, mode }) => {
      injectors.injectSaga(key, { saga, mode });
    });

    return () => {
      sagas.forEach(({ key }) => {
        injectors.ejectSaga(key);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
