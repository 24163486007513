import { Badge as EvergreenBadge } from 'evergreen-ui';
import React, { forwardRef } from 'react';

import Box from '~/components/Box';
import styled, { transformStylePropsForEvergreen } from '~/lib/styled';

export default forwardRef(function Badge(props, ref) {
  return (
    <EvergreenBadge ref={ref} {...transformStylePropsForEvergreen(props)} />
  );
});

export const SplitBadge = styled(Box)({
  '> :first-child': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '> :last-child': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
