import { put, takeLatest } from 'redux-saga/effects';

import { openIdConnect } from '~/lib/api';
import { login } from '~/lib/api';

/**
 * Take latest `REGISTER` action and begin a registration
 */
export default function* openIdSaga() {
  yield takeLatest(openIdConnect.SUCCESS, logUserIn);
}

export function* logUserIn({ payload, meta }) {
  const { authorization } = meta;
  yield put(login.success(payload, { headers: { authorization } }));
}
