import { createAction } from 'redux-actions';

// ------------------------------------
// Actions
// ------------------------------------

export const IMPORT_POLL = 'IMPORT_POLL';
export const importPoll = createAction(IMPORT_POLL);

export const IMPORT_TRIGGER = 'IMPORT_TRIGGER';
export const trigger = createAction(IMPORT_TRIGGER);

export const IMPORT_COMPLETE = 'IMPORT_COMPLETE';
export const complete = createAction(IMPORT_COMPLETE);

export const IMPORT_FAIL = 'IMPORT_FAIL';
export const fail = createAction(IMPORT_FAIL);
