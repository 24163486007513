import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

import { injectIntl } from '~/components/Intl';

import DatePicker from './DayPicker';

export const dateFormat = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
};

function IntlDatePicker({ value, onChange, intl, ...props }) {
  const handleDatePicked = (e) => {
    onChange({
      target: { value: intl.formatDate(e.target.value, dateFormat) },
    });
  };
  const date = intl.formatDate(value, dateFormat);

  return <DatePicker {...props} value={date} onChange={handleDatePicked} />;
}

IntlDatePicker.propTypes = {
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default compose(injectIntl)(IntlDatePicker);
