import {
  SearchTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextTableCell,
  TextTableHeaderCell,
} from 'evergreen-ui';

// Add wai-aria roles to the table elements as default props
Table.defaultProps = {
  role: 'table',
};
TableCell.defaultProps = {
  role: 'cell',
};
TableHead.defaultProps = {
  role: 'rowgroup',
};
TableHeaderCell.defaultProps = {
  role: 'columnheader',
};
TableBody.defaultProps = {
  role: 'rowgroup',
};
TableRow.defaultProps = {
  role: 'row',
};

export {
  SearchTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TextTableCell,
  TextTableHeaderCell,
};

const FlexTable = {
  Table: Table,
  Body: TableBody,
  Head: TableHead,
  HeaderCell: TableHeaderCell,
  TextHeaderCell: TextTableHeaderCell,
  SearchHeaderCell: SearchTableHeaderCell,
  Row: TableRow,
  Cell: TableCell,
  TextCell: TextTableCell,
};

export default FlexTable;
