import PropTypes from 'prop-types';
import React from 'react';

import Box from '~/components/Box';
import Heading from '~/components/Heading';

class EditorToolbar extends React.Component {
  static propTypes = {
    container: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    toolbarComponent: PropTypes.func.isRequired,
  };

  static styles = {
    pb: 2,
  };

  render() {
    const { label, toolbarComponent: Toolbar } = this.props;

    return (
      <Box {...EditorToolbar.styles}>
        <div>
          <Heading display="inline-block" minWidth={70} size={100}>
            {label}
          </Heading>
          <Toolbar {...this.props} />
        </div>
      </Box>
    );
  }
}

export default EditorToolbar;
