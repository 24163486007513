import { getOr, isFunction } from 'lodash/fp';
import { parse } from 'qs';
import { createSelector } from 'reselect';

import { StatusTypes } from './constants';

// const getOrZero = getOr(0);
// const getOrArr = getOr([]);
const getOrFalse = getOr(false);
const getOrString = getOr('');
const getOrObj = getOr({});
const getOrNotLoaded = getOr(StatusTypes.NOT_LOADED);

const selectApiPart = (key) => getOrObj(['api', key]);

const createSelectRef = (selectCreator) => (getRef) => {
  const selector = selectCreator((state, props = {}) => getRef(props.id));

  // Either create another selector with a new "selectRef"
  return (...args) => {
    if (args.length === 1 && isFunction(args[0])) {
      const selectRef = createSelector(...args, getRef);
      return selectCreator(selectRef);
    }

    return selector(...args);
  };
};

const getOrPagination = getOr({
  page: 0,
  total: 0,
  links: '',
  perPage: 0,
});
export const makePaginationSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('pagination'), getOrPagination)
);

export const makePaginationNextPageSelector = createSelectRef((selectRef) =>
  createSelector(
    createSelector(selectRef, selectApiPart('pagination'), getOrPagination),
    (pagination) => {
      if (!pagination.links) return 0;

      let link = pagination.links
        .split(/,\s*</)
        .filter((link) => link.includes('rel="next"'))
        .pop();

      if (!link) {
        return 0;
      }

      link = link.match(/<?([^>]*)>(.*)/)[1].split('?')[1];

      return Number(parse(link).page);
    }
  )
);

export const makeLoadingSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('loading'), getOrFalse)
);

export const makeResultsSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('results'), getOrObj)
);

export const makeErrorSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('error'), getOrFalse)
);

export const makeErrorMessageSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('errorMessage'), getOrString)
);

export const makeStatusSelector = createSelectRef((selectRef) =>
  createSelector(selectRef, selectApiPart('status'), getOrNotLoaded)
);

export const makeIsStatusSelector = (status) =>
  createSelectRef((selectRef) =>
    createSelector(
      selectRef,
      selectApiPart('status'),
      (ref, statuses) => getOrNotLoaded(ref, statuses) === status
    )
  );

// const makeTotalSelector = selectRef =>
//   createSelector(makePaginationSelector(selectRef), getOrZero('total'));

export default (actionDescriptor) => {
  const { getRef } = actionDescriptor;
  return {
    selectStatus: makeStatusSelector(getRef),
    selectLoading: makeIsStatusSelector(StatusTypes.LOADING)(getRef),
    selectPagination: makePaginationSelector(getRef),
    selectNextPage: makePaginationNextPageSelector(getRef),
    selectResults: makeResultsSelector(getRef),
    selectSuccess: makeIsStatusSelector(StatusTypes.LOADED)(getRef),
    selectError: makeErrorSelector(getRef),
    selectErrorMessage: makeErrorMessageSelector(getRef),
  };
};
