export const setLocation = (url) => {
  window.location.assign(url);
};

export function getLocation() {
  return window.location;
}

export function currentLocation() {
  return window.location.pathname + window.location.search;
}
