import hoistNonReactStatics from 'hoist-non-react-statics';
import { flatMap } from 'lodash/fp';
import React from 'react';
import { ReactReduxContext } from 'react-redux';

import getInjectors from './reducerInjectors';

const flatMapReducers = flatMap((c) => {
  return c.reducers || c;
});

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default (reducers) => {
  const flat = flatMapReducers(reducers);
  const ComponentWithInjector = (WrappedComponent) => {
    class ReducersInjector extends React.Component {
      static WrappedComponent = WrappedComponent;
      static contextType = ReactReduxContext;
      static displayName = `withReducer(${flat.map((r) => r.key)})`;

      state = {
        injected: false,
      };

      componentDidMount() {
        const { injectReducer } = this.injectors;

        flat.forEach(({ key, reducer }) => {
          injectReducer(key, reducer);
        });

        this.setState({ injected: true });
      }

      injectors = getInjectors(this.context.store);

      render() {
        if (!this.state.injected) {
          return null;
        }

        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducersInjector, WrappedComponent);
  };

  ComponentWithInjector.reducers = reducers;

  return ComponentWithInjector;
};

export function useInjectReducers(reducers) {
  const context = React.useContext(ReactReduxContext);
  React.useEffect(() => {
    const { injectReducer } = getInjectors(context.store);
    flatMapReducers(reducers).forEach(({ key, reducer }) => {
      injectReducer(key, reducer);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
