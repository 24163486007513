import { createGlobalStyle } from '~/lib/styled';
import theme from '~/theme';

/* eslint no-unused-expressions: 0 */
export default createGlobalStyle([
  `
  html,
  body {
    height: 100%;
    width: 100%;
    cursor: auto;
  }

  body {
    font-size: ${theme.fontSizes[1]}px;
    line-height: 1;
    color: ${theme.colors.text};
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: -apple-system-headline, -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0;
  }

  p {
    margin: 0 0 0.5em;
  }

  hr {
    border-bottom: ${theme.borders.default};
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: 1em 0;
  }

  button {
    border: none;
  }

  ::placeholder {
    color: rgba(66, 90, 112, 0.8);
  }

  /**
   * Remove default link styles, since they get used for buttons, etc.
   */

  a {
    color: inherit;
    text-decoration: none;
  }

  /**
   * Remove outline for links. You should style it, but not necessarily outline.
   */

  a:focus {
    outline: 0;
  }

  /**
   * React Day Picker component
   */
  body .DayPicker {
    font-size: 1em;
  }

  @media print {
    html,
    body,
    #app {
      height: auto;
    }
  }

  /**
   * The rest of this file is taken from
   * https://github.com/csstools/sanitize.css' forms.css but without the
   * background-image on the select because it is too hard to remove
   */

  /**
   * 1. Change the inconsistent appearance in all browsers (opinionated).
   * 2. Add typography inheritance in all browsers (opinionated).
   */

  button,
  input,
  select,
  textarea {
    background-color: transparent; /* 1 */
    border: 1px solid WindowFrame; /* 1 */
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    letter-spacing: inherit; /* 2 */
    padding: 0.25em 0.375em; /* 1 */
  }

  /**
   * Change the inconsistent appearance in all browsers (opinionated).
   */

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: no-repeat right center / 1em;
    border-radius: 0;
    padding-right: 1em;
  }

  /**
   * Change the inconsistent appearance in all browsers (opinionated).
   */

  /*select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='4'%3E%3Cpath d='M4 0h6L7 4'/%3E%3C/svg%3E");
  }*/

  /**
   * Change the inconsistent appearance in IE (opinionated).
   */

  ::-ms-expand {
    display: none;
  }

  /**
   * Correct the inconsistent appearance in IE (opinionated).
   */

  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
`,
]);
