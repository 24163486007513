const incidentsUrl =
  'https://status.creditorwatch.com.au/api/v2/incidents/unresolved.json';
const maintenanceUrl =
  'https://status.creditorwatch.com.au/api/v2/scheduled-maintenances.json';

const THIRTY_MINUTES = 30 * 60 * 1000; // 30 minutes in milliseconds

export default async function fetchStatuses({
  componentName,
  fetch = globalThis.fetch,
}) {
  const responses = await Promise.all([
    fetch(incidentsUrl),
    fetch(maintenanceUrl),
  ]);
  const [{ incidents }, { scheduled_maintenances }] = await Promise.all(
    responses.map((res) => res.json())
  );

  const incident = incidents.find((i) =>
    i.components.some((c) => c.name === componentName)
  );
  const scheduledMaintenances = scheduled_maintenances.filter(
    (m) =>
      m.components.some((c) => c.name === componentName) &&
      m.status !== 'completed'
  );

  const maintenance =
    scheduledMaintenances.find(
      (m) => m.status === 'in_progress' || m.status === 'verifying'
    ) ||
    scheduledMaintenances.find(
      (m) => m.status === 'scheduled' && isWithinMaintenanceWindow(m)
    );

  return {
    incident,
    maintenance,
  };
}

function isWithinMaintenanceWindow(maintenance) {
  const now = Date.now();
  const start = new Date(maintenance.scheduled_for).getTime();
  const end = new Date(maintenance.scheduled_until).getTime();

  // 30 mins from now is after the start time and before the end
  return now + THIRTY_MINUTES >= start && now <= end;
}
