import React from 'react';

import decorateComponentWithProps from '~/utils/decorateComponentWithProps';

import { Bold, Italic, Underline } from './Buttons';
import Toolbar from './Toolbar';

export default (config = {}) => {
  const toolbarProps = {
    inlineButtons: config.inlineButtons || [Bold, Italic, Underline],
    blockButtons: config.blockButtons || [],
  };

  return {
    styleToHTML: (style) => {
      switch (style) {
        case 'BOLD':
          return <strong />;
        case 'ITALIC':
          return <em />;
        case 'UNDERLINE':
          return <u />;
        default:
          return '';
      }
    },
    Toolbar: decorateComponentWithProps(Toolbar, toolbarProps),
  };
};
