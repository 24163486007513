/**
 * Adds a sticker to an editor state
 */

import { EditorState, Modifier } from 'draft-js';

import { ENTITY_TYPE } from './constants';

export default (editorState, placeholder, fallback) => {
  const placeholderText = `{${placeholder}${
    fallback ? `, fallback="${fallback}"` : ''
  }}`;
  const currentContentState = editorState.getCurrentContent();
  const currentSelectionState = editorState.getSelection();

  // in case text is selected it is removed and then the sticker is appended
  const afterRemovalContentState = Modifier.removeRange(
    currentContentState,
    currentSelectionState,
    'backward'
  );

  // deciding on the postion to split the text
  const targetSelection = afterRemovalContentState.getSelectionAfter();

  const afterEntityCreation = afterRemovalContentState.createEntity(
    ENTITY_TYPE,
    'IMMUTABLE',
    { placeholder, fallback }
  );

  const lastEntityKey = afterEntityCreation.getLastCreatedEntityKey();

  const afterInsertionContentState = Modifier.insertText(
    afterEntityCreation,
    targetSelection,
    placeholderText,
    null,
    lastEntityKey
  );

  const newState = EditorState.push(
    editorState,
    afterInsertionContentState,
    'insert-characters'
  );

  // return newState;
  return EditorState.forceSelection(
    newState,
    afterInsertionContentState.getSelectionAfter()
  );
};
