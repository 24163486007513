import { RichUtils } from 'draft-js';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/components/Button';

export default ({ style, children }) =>
  class InlineStyleButton extends React.Component {
    static displayName = style;

    static propTypes = {
      getEditorState: PropTypes.func.isRequired,
      setEditorState: PropTypes.func.isRequired,
    };

    toggleStyle = (event) => {
      event.preventDefault();

      this.props.setEditorState(
        RichUtils.toggleInlineStyle(this.props.getEditorState(), style)
      );
    };

    preventBubblingUp = (event) => {
      event.preventDefault();
    };

    styleIsActive = () =>
      this.props.getEditorState().getCurrentInlineStyle().has(style);

    render() {
      const active = this.styleIsActive();
      return (
        <Button
          type="button"
          onClick={this.toggleStyle}
          onMouseDown={this.preventBubblingUp}
          py={1}
          data-active={active ? true : undefined}
        >
          {children}
        </Button>
      );
    }
  };
