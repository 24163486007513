import { ENTITY_TYPE } from './constants';

const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    if (entityKey === null) {
      return false;
    }

    return contentState.getEntity(entityKey).getType() === ENTITY_TYPE;
  }, callback);
};

export default linkStrategy;
