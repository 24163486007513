import createEditor from './createEditor';
import createLinkPlugin from './plugins/Link';
import createPlaceholderPlugin from './plugins/Placeholder';
import createPlainTextPlugin from './plugins/PlainText';
import createSingleLinePlugin from './plugins/SingleLine';
import createTextColorPlugin from './plugins/TextColor';
import createToolbarPlugin from './plugins/Toolbar';

const linkPlugin = createLinkPlugin();
const { LinkButton } = linkPlugin;
const toolbarPlugin = createToolbarPlugin({
  blockButtons: [LinkButton],
});
const { Toolbar } = toolbarPlugin;

const plugins = [linkPlugin, toolbarPlugin];

export default createEditor('Editor', plugins, Toolbar);

export {
  createEditor,
  createLinkPlugin,
  createPlaceholderPlugin,
  createPlainTextPlugin,
  createSingleLinePlugin,
  createTextColorPlugin,
  createToolbarPlugin,
};
