/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';

import Box from '~/components/Box';
import Heading from '~/components/Heading';

export default function NotFound() {
  return (
    <Box p={6}>
      <Heading size={900} textAlign="center">
        Page not found.
      </Heading>
    </Box>
  );
}
