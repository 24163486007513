import { EditorState,SelectionState } from 'draft-js';

import getEntityRange from '../../utils/getEntityRange';

// Set selection of editor to next/previous block
export default (getEditorState, setEditorState, entity) => {
  const editorState = getEditorState();
  const range = getEntityRange(editorState, entity.entityKey);

  setEditorState(
    EditorState.forceSelection(
      editorState,
      new SelectionState({
        anchorKey: entity.blockKey,
        anchorOffset: range.start,
        focusKey: entity.blockKey,
        focusOffset: range.end,
        isBackward: false,
      })
    )
  );
};
