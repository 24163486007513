import { injectReducers } from '~/utils/injectors';

import { default as forgot, key as forgotKey } from './forgot';
import { default as reset, key as resetKey } from './reset';

export { makeSelectSent } from './forgot';
export { selectResetSuccess, selectResetTokenError } from './reset';

export default injectReducers([
  { key: forgotKey, reducer: forgot },
  { key: resetKey, reducer: reset },
]);
