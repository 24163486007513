import Box from '~/components/Box';
import styled from '~/lib/styled';

export const ButtonGroup = styled(Box, { mr: '4px' })((p) => ({
  display: 'inline-block',
  marginRight: '4px',

  '& > :not(:first-child):not(:last-child)': {
    borderRadius: 0,
    marginLeft: '-1px' /* hide the left border behind previous element */,
  },

  '& > :first-child:not(:last-child)': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  '& > :last-child:not(:first-child)': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: '-1px' /* hide the left border behind previous element */,
  },
}));

export default ButtonGroup;
