import { isPlainObject, isUndefined, reduce } from 'lodash';
import { stringify } from 'qs';

const flattenObject = (parentKey, obj) =>
  reduce(
    obj,
    (m, v, k) =>
      isUndefined(v)
        ? m
        : {
            ...m,
            [`${parentKey}[${k}]`]: v,
          },
    {}
  );

/**
 * Remove params from the body and add to the url, if possible
 */
export default function parameterizeUrl(request) {
  const { url, body: params } = request;
  const p = {
    ...params,
  };

  return {
    ...request,
    url: url.fill((key) => {
      // Remove array-like parameters
      const parameterKey = key.replace('[]', '');

      if (Reflect.has(p, parameterKey)) {
        const found = p[parameterKey];
        delete p[parameterKey];

        // Allow params to get URL encoded using Qs, which handles arrays better
        if (parameterKey === 'params') {
          return stringify(found, {
            addQueryPrefix: true,
            encode: false,
            arrayFormat: 'brackets',
          });
        }

        // If we've got an object of objects, we need to flatten it:
        // { params: { show: [1, 2], meta: { data: 'none' } } }
        // =>
        // {
        //   show[]: 1,
        //   show[]: 2,
        //   meta[data]: 'none',
        // }
        if (isPlainObject(found)) {
          return reduce(
            found,
            (memo, value, key) => {
              if (isPlainObject(value)) {
                return {
                  ...memo,
                  ...flattenObject(key, value),
                };
              }

              // if (Array.isArray(value)) {
              //   return Object.assign({}, value);
              // }

              return {
                ...memo,
                [key]: value,
              };
            },
            {}
          );
        }

        return found;
      }

      return;
    }),
    body: p,
  };
}
