import { push } from 'redux-first-history';
import { put, takeLatest } from 'redux-saga/effects';

import { login } from '~/lib/api';

/**
 * Check the current route and every route change for a private route. Then
 * redirect to the login view.
 */
export default function* redirectOnLoginSaga() {
  yield takeLatest(login.SUCCESS, redirect);
}

export function* redirect() {
  // Redirect back unless that was the login view
  yield put(push('/'));
}
