import { push } from 'redux-first-history';
import { call, put, take, takeLatest } from 'redux-saga/effects';

import { UNAUTHORISED } from '~/actions/auth';
import { login } from '~/lib/api';

import { getLocation } from '../helpers';

/**
 * Check the current route and every route change for a private route. Then
 * redirect to the login view.
 */
export default function* unauthorisedAuthSaga() {
  yield takeLatest(UNAUTHORISED, forceLogin);
}

export function* forceLogin() {
  const location = Object.assign({}, yield call(getLocation));
  const { pathname, search, hash } = location;

  yield put(push('/login'));

  yield take(login.SUCCESS);

  // Redirect back unless that was the login view
  yield put(
    push({
      search,
      hash,
      pathname: pathname.match(/^\/(login)/) ? '/' : pathname,
    })
  );
}
