import React, { useEffect, useState } from 'react';

import fetchStatuses from './fetchStatuses';
import StatusBannerStateless from './StatusBannerStateless';

const APP_NAME = 'CreditorWatch Collect';

const StatusBanner = () => {
  const POLL_DELAY = 600_000; // 10 minute
  const [status, setStatus] = useState(null);

  useEffect(() => {
    async function checkStatus() {
      const { incident, maintenance } = await fetchStatuses({
        componentName: APP_NAME,
      });
      const isScheduled = maintenance?.status === 'scheduled';

      if (incident) {
        // Show notification
        setStatus({
          intent: 'danger',
          title: incident.name,
          link: incident.shortlink,
        });
      } else if (maintenance && !isScheduled) {
        setStatus({
          title: maintenance.incident_updates[0].body || maintenance.name,
          link: maintenance.shortlink,
        });
      } else if (maintenance && isScheduled) {
        setStatus({
          appearance: 'muted',
          title: maintenance.name,
          link: maintenance.shortlink,
          scheduledFor: maintenance.scheduled_for,
          scheduledUntil: maintenance.scheduled_until,
        });
      } else {
        setStatus(null);
      }
    }

    checkStatus();
    const intervalId = setInterval(checkStatus, POLL_DELAY);

    return () => clearInterval(intervalId);
  }, []);

  if (!status) return null;

  return <StatusBannerStateless {...status} />;
};

export default StatusBanner;
