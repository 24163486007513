import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import LoadingIndicator from '~/components/LoadingIndicator';
import { API_ROOT, publicInvoiceFind, routes } from '~/lib/api';
import NotFoundPage from '~/pages/NotFoundPage';

import PublicInvoiceDetail from './PublicInvoiceDetail';

export function PublicInvoicePage({ invoice, publicInvoiceFindRequest }) {
  const { hash, contactHash } = useParams();
  React.useEffect(() => {
    publicInvoiceFindRequest({ hash });
  }, [publicInvoiceFindRequest, hash]);

  if (invoice.redirectTo) {
    window.location = invoice.redirectTo;
    return null;
  }

  // If the invoice only contains a redirect to, but it's `null` then there
  // must be something wrong on the api side.
  if (invoice.redirectTo === null) {
    return <NotFoundPage />;
  }

  const pdfLink = `${API_ROOT}${routes.publicInvoice.pdf.urlTemplate.fill({
    hash,
  })}`;

  if (isEmpty(invoice)) {
    return <LoadingIndicator block p={4} />;
  }

  return (
    <div>
      <PublicInvoiceDetail
        {...invoice}
        contactHash={contactHash}
        pdfLink={pdfLink}
      />
    </div>
  );
}

PublicInvoicePage.propTypes = {
  hash: PropTypes.string.isRequired,
  publicInvoiceFindRequest: PropTypes.func.isRequired,
  invoice: PropTypes.object,
};

const mapDispatchToProps = {
  publicInvoiceFindRequest: publicInvoiceFind.request,
};

const mapStateToProps = createStructuredSelector({
  invoice: publicInvoiceFind.selectResults,
  loading: publicInvoiceFind.selectLoading,
  error: publicInvoiceFind.selectError,
  errorMessage: publicInvoiceFind.selectErrorMessage,
});

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(PublicInvoicePage);
