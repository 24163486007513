import Pane from '~/components/Pane';
import styled, {
  borderRadius,
  opacity,
  overflow,
  style,
  variantTransform,
  zIndex,
} from '~/lib/styled';

const elevationStyle = style({
  prop: 'elevation',
  cssProperty: 'boxShadow',
  key: 'elevations',
});

const hoverElevationStyle = variantTransform({
  prop: 'hoverElevation',
  key: 'elevations',
  transformFn: (n) => ({
    transitionDuration: '150ms',
    transitionProperty: 'box-shadow, transform',
    transitionTimingFunction: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: n,
    },
  }),
});

export const Card = styled(Pane, { borderRadius: 2 })(
  ({ onClick }) => onClick && { cursor: 'pointer' },
  opacity,
  zIndex,
  overflow,
  borderRadius,
  elevationStyle,
  hoverElevationStyle
);

export default Card;
