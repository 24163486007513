import { toaster } from 'evergreen-ui';
import React from 'react';
import { connect } from 'react-redux';

import ErrorMessage from '~/components/ErrorMessage';
import Paragraph from '~/components/Paragraph';
import { getJsonItem, setJsonItem } from '~/lib/localstorage';
import { selectAppError, selectAppErrorVisible } from '~/reducers/error';
import ErrorReporter from '~/utils/errorReporter';

const RELOAD_DELAY = 500;
const STORAGE_KEY = 'dd.error';

// Special HTTP error codes
const SERVICE_UNAVAILABLE = 503;

export function ErrorBoundary({ title, error = {}, children }) {
  const { code, message } = error;
  // If we have captured an error from lib/api/saga.js, throw a toast.
  React.useEffect(() => {
    if (code && code !== SERVICE_UNAVAILABLE) {
      if (title) {
        toaster.danger(title, { description: message });
      } else {
        toaster.danger(message);
      }
    }
  }, [code, message]);

  // If we have a maintenance error, render the error message.
  if (code === SERVICE_UNAVAILABLE) {
    return (
      <ErrorMessage
        title={title}
        error={error}
        intent="warning"
        message={
          <Paragraph>
            Sorry for the inconvenience but we&rsquo;re performing some
            maintenance at the moment. We&rsquo;ll be back online shortly!
          </Paragraph>
        }
      />
    );
  }

  return (
    <ErrorReporter.ErrorBoundary
      fallback={<ErrorMessage title={title} error={error} />}
      onError={onError}
    >
      {children}
    </ErrorReporter.ErrorBoundary>
  );
}

function onError(error) {
  if (requiresReload(error)) {
    const { refreshCount = 0 } = getJsonItem(STORAGE_KEY) || {};

    if (refreshCount < 2) {
      setJsonItem(STORAGE_KEY, {
        refreshCount: refreshCount ? refreshCount + 1 : 1,
      });

      return setTimeout(
        () => window.location.reload(true),
        RELOAD_DELAY * refreshCount
      );
    }
  }
}

function requiresReload(error) {
  // Message comes from Loadable Components when it fails to load a chunk
  return error?.message?.startsWith(
    'Failed to fetch dynamically imported module'
  );
}

const mapStateToProps = (state) => {
  const error = selectAppError(state);
  return {
    hasVisibleError: selectAppErrorVisible(state),
    error: error,
    title: error?.title || 'Unknown Error',
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
