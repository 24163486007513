import {
  actionChannel,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';

import { unauthorised } from '~/actions/auth';
import { updateAppError } from '~/actions/error';
import { selectCurrentOrganisationIdFromStore } from '~/reducers/currentOrganisation';
import ErrorReporter from '~/utils/errorReporter';

export const tokenSelector = (state) => state.auth && state.auth.token;
export const isApiAction = ({ meta }) => meta && meta.api;

/**
 * Take every `API_REQUEST` action and begin a request
 * with `apiFetchRequest(action.payload)`
 */
export function* fetchSaga() {
  let tasks = {};
  while (true) {
    const action = yield take(isApiAction);

    const task = tasks[action.type];

    // API actions that block mean any new action of the same type will wait
    // for the last call to complete before starting the next one. Useful
    // for PUT/POST calls of partial data
    if (action.meta.blocking) {
      if (!task || !task.isRunning()) {
        const channel = yield actionChannel(action.type);
        tasks[action.type] = yield fork(fetchOneAtATime, action, channel);
      }

      // If we have the task, then it is already listening for this blocking
      // event, so we don't have to do anything more
      continue;
    }

    if (action.meta.debounce && tasks[action.type]) {
      yield cancel(tasks[action.type]);
    }

    tasks[action.type] = yield fork(apiFetchWithStateCheck, action);
  }
}

export function* fetchOneAtATime(firstAction, channel) {
  let action = firstAction;
  while (true) {
    yield call(apiFetchWithStateCheck, action);
    action = yield take(channel);
  }
}

export default fetchSaga;

/**
 * Begin an api request if state is correct
 * -  Check that we should send this request by looking at
 *    the state or return.
 * -  Call apiFetch
 *
 * @param {Object} options.payload: p inner payload
 */
export function* apiFetchWithStateCheck({ type, meta, payload }) {
  const { stateCheck, debounce } = meta;

  if (stateCheck) {
    if (!(yield select(stateCheck))) {
      return;
    }
  }

  if (debounce) {
    yield delay(debounce === true ? 250 : debounce);
  }

  yield call(apiFetch, type, payload, meta);
}

/**
 * Make an api request.
 * -  Add the authorisation headers, if exists
 * -  Call api method
 * -  Dispatch REQUEST_SUCCESS or REQUEST_FAILED
 *    with the payload or error
 * -  Normalize response if schema exists
 *
 * @param {Constant} type        Triggered action
 * @param {Object} payload       Payload to send to endpoint
 * @param {Object} meta          Meta containing api fn
 * @returns {Object} The response
 */
export function* apiFetch(type, payload = null, meta = null) {
  let response;
  let finalPayload = payload;
  const { actionId, ref, api: endpoint } = meta;
  const { success, failure } = meta.actions;
  const headers = yield call(getHeaders, meta.headers);

  if (requiresOrganisation(endpoint, payload)) {
    const organisationId = yield select(selectCurrentOrganisationIdFromStore);
    finalPayload = { ...finalPayload, organisationId };
  }

  try {
    response = yield call(endpoint, finalPayload, headers);

    const { body, headers: responseHeaders } = response;

    if (body && finalPayload && finalPayload.organisationId) {
      body.organisationId = finalPayload.organisationId;
    }

    yield put(
      success(body, {
        ref,
        actionId,
        payload: finalPayload,
        headers: responseHeaders,
      })
    );
  } catch (error) {
    if (!error.status) {
      // Log non-network errors
      ErrorReporter.report(error, { action: type });
      yield put(failure(error, { ref, actionId, payload: finalPayload }));
      return;
    }

    // Handle API down for maintenance
    if (error.status == 503) {
      yield put(
        updateAppError({
          code: 503,
          title: 'Down for maintenance',
          ...error,
        })
      );
      return;
    }

    // Handle API server errors by throwing
    if (error.status >= 500) {
      yield put(
        updateAppError({
          code: error.status || 500,
          ...error,
        })
      );
    }

    if (error.status === 401 && !meta.ignoreAuth) {
      yield put(unauthorised());
      return; // Early return prevents error being raised below
    }

    if (error.status === 403 && !meta.ignoreAuth) {
      yield put(
        updateAppError({
          code: error.status,
          title: 'Permission denied',
          message:
            error.message ||
            'You’ll need to ask the Organisation Owner to do this action.',
        })
      );
    }

    // Unprocessable Entity: Validation Error
    if (
      error.status === 422 &&
      error.response?.resolvedBody?.fullErrorMessages
    ) {
      const errors = error.response?.resolvedBody || {};
      const action = failure(errors, { ref, actionId, payload: finalPayload });

      action.error = true;

      // Raise Error, but with access to the validation errors
      yield put(action);
      return;
    }

    yield put(failure(error, { ref, actionId, payload: finalPayload }));
  }

  // Allows other sagas to call this directly
  return response;
}

// Select the auth token from the store
export function* getHeaders(metaHeaders = {}) {
  const tokenHeaders = {};
  const token = yield select(tokenSelector);

  if (token) {
    // eslint-disable-next-line dot-notation
    tokenHeaders['Authorization'] = token;
  }

  return {
    ...tokenHeaders,
    ...metaHeaders,
  };
}

function requiresOrganisation(request, payload) {
  return (
    (!payload || !payload.organisationId) &&
    request.urlTemplate.toString().includes('organisation_id')
  );
}
