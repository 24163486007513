import invariant from 'invariant';
import { camelCase, isFunction } from 'lodash';
import { createAction } from 'redux-actions';

import { createPromiseAction } from '~/lib/promise';

import { Types } from './constants';
import createApiSelectors from './createApiSelectors';

let actionId = 0;

export default function createApiAction(typePrefix, options = {}) {
  const {
    api,
    payloadCreator,
    metaCreator,
    successPayloadCreator,
    failurePayloadCreator,
    successMetaCreator,
    failureMetaCreator,
    blocking,
  } = options;

  invariant(
    isFunction(api),
    '(api) createApiActions: Expected a valid api function'
  );

  const baseRef = camelCase(typePrefix);
  const getRef = (id) => (id ? `${baseRef}/${id}` : baseRef);

  const finalMetaCreator = (payload, meta) => ({
    actionId: actionId++,
    ref: getRef(payload && payload.id),
    api: api,
    actions: { success: actions.success, failure: actions.failure },
    blocking,
    ...(meta && meta.debounce ? { debounce: meta.debounce } : {}),
    ...(isFunction(metaCreator) ? metaCreator(payload, meta) : {}),
  });

  const finalSuccessMetaCreator = (payload, { payload: op, ...meta }) => ({
    actionId: meta.actionId,
    ref: meta.ref,
    ...(op ? { originalPayload: op } : {}),
    ...(meta.headers?.perPage
      ? {
          pagination: {
            link: meta.headers.link || '',
            perPage: Number(meta.headers.perPage),
            total: Number(meta.headers.total),
          },
        }
      : {}),
    ...(meta.headers?.authorization
      ? {
          authorization: meta.headers.authorization,
        }
      : {}),
    ...(isFunction(successMetaCreator)
      ? successMetaCreator(payload, meta)
      : {}),
  });

  const finalFailureMetaCreator = (payload, { payload: op, ...meta }) => ({
    actionId: meta.actionId,
    ref: meta.ref,
    ...(op ? { originalPayload: op } : {}),
    ...(isFunction(failureMetaCreator)
      ? failureMetaCreator(payload, meta)
      : {}),
  });

  // TODO: this stinks
  const finalCancelAndInvalidateMetaCreator = (payload) => ({
    ref: getRef(payload && payload.id),
  });

  const REQUEST = `${typePrefix}/${Types.REQUEST}`;
  const SUCCESS = `${typePrefix}/${Types.SUCCESS}`;
  const FAILURE = `${typePrefix}/${Types.FAILURE}`;
  const CANCEL = `${typePrefix}/${Types.CANCEL}`;
  const INVALIDATE = `${typePrefix}/${Types.INVALIDATE}`;

  const actions = {
    request: createPromiseAction(
      REQUEST,
      SUCCESS,
      FAILURE,
      payloadCreator,
      finalMetaCreator
    ),
    success: createAction(
      SUCCESS,
      successPayloadCreator,
      finalSuccessMetaCreator
    ),
    failure: createAction(
      FAILURE,
      failurePayloadCreator,
      finalFailureMetaCreator
    ),
    cancel: createAction(
      CANCEL,
      undefined,
      finalCancelAndInvalidateMetaCreator
    ),
    invalidate: createAction(
      INVALIDATE,
      undefined,
      finalCancelAndInvalidateMetaCreator
    ),
  };

  return {
    api,
    actionId,
    TYPE: typePrefix,
    REQUEST: REQUEST,
    SUCCESS: SUCCESS,
    FAILURE: FAILURE,
    ...actions,
    ...createApiSelectors({ getRef, schema: api.schema }),
  };
}
