import 'draft-js/dist/Draft.css';

import Editor from '@draft-js-plugins/editor';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState, RichUtils } from 'draft-js';
import {
  handleDraftEditorPastedText,
  registerCopySource,
} from 'draftjs-conductor';
import { debounce, set } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

import Pane from '~/components/Pane';
import styled, { themed } from '~/lib/styled';

import FieldError from '../FieldError';
import EditorToolbar from './EditorToolbar';
import toConvertMiddleware from './toConvertMiddleware';
import keyBindingFn from './utils/keyBindingFn';

const EditorWrapper = styled(Pane)(
  themed({
    lineHeight: 'copy',
    '& .public-DraftStyleDefault-block': {
      mb: 3,
    },
  })
);

export default function createEditor(
  label,
  plugins,
  Toolbar,
  defaultProps = {}
) {
  const fromHTML = convertFromHTML(toConvertMiddleware(plugins));
  const toHTML = convertToHTML(toConvertMiddleware(plugins));

  return class BasicEditor extends React.Component {
    static displayName = `${label}Editor`;

    static propTypes = {
      placeholders: PropTypes.array,
      field: PropTypes.object.isRequired,
      form: PropTypes.object.isRequired,
    };

    static defaultProps = {
      maxWidth: 6,
    };

    static fromHTML = fromHTML;
    static toHTML = toHTML;

    state = {
      editorState: EditorState.createWithContent(
        fromHTML(this.props.field.value)
      ),
    };

    constructor(props) {
      super(props);
      this.editor = null;
    }

    componentDidMount() {
      if (this.editor?.editor) {
        this.copySource = registerCopySource(this.editor?.editor);
      }

      // Reset the form's initial values to the object generated by toHTML
      // so that the wrapping form can just submit.
      // This means that the initial form may not be dirty when first touched
      const { form, field } = this.props;

      if (!form.dirty) {
        const values = set(field.name, toHTML(fromHTML(field.value)), {
          ...form.values,
        });

        form.resetForm({
          values,
        });
      }
    }

    componentWillUnmount() {
      if (this.copySource) {
        this.copySource.unregister();
      }
    }

    getEditorState = () => this.state.editorState;

    focus = (e) => {
      if (!e || !e.defaultPrevented) {
        this.editor.focus();
      }
    };

    setFieldValue = debounce(120, (newContent) => {
      const { form, field } = this.props;
      form.setFieldValue(field.name, toHTML(newContent));
    });

    handleChange = (editorState) => {
      const newContent = editorState.getCurrentContent();

      // Only update the field value if the *content* has actually changed,
      // not just the entities or selection states
      if (newContent !== this.state.editorState.getCurrentContent()) {
        this.setFieldValue(newContent);
      }

      this.setState({ editorState });
    };

    handleKeyCommand = (command) => {
      const { editorState } = this.state;

      if (command === 'soft-return') {
        this.setState({
          editorState: RichUtils.insertSoftNewline(editorState),
        });
        return 'handled';
      }

      return 'not-handled';
    };

    handlePastedText = (text, html, editorState) => {
      let newState = handleDraftEditorPastedText(html, editorState);

      if (newState) {
        this.handleChange(newState);
        return 'handled';
      }

      return false;
    };

    render() {
      const { affix, ...defaultEditorProps } = defaultProps;
      const { field, toolbarProps, editorProps, maxWidth } = this.props;
      const { handleBlur } = field;
      const { editorState } = this.state;

      return (
        <EditorWrapper
          data-testid={`editor-${label}`}
          maxWidth={maxWidth}
          onClick={this.focus}
        >
          <FieldError name={field.name} />
          <EditorToolbar
            label={label}
            container={this}
            toolbarComponent={Toolbar}
            getEditorState={this.getEditorState}
            setEditorState={this.handleChange}
            focus={this.focus}
            {...toolbarProps}
          />
          <Editor
            {...defaultEditorProps}
            ref={(editor) => {
              this.editor = editor;
            }}
            editorState={editorState}
            onChange={this.handleChange}
            handleKeyCommand={this.handleKeyCommand}
            handlePastedText={this.handlePastedText}
            keyBindingFn={keyBindingFn}
            onBlur={handleBlur}
            plugins={plugins}
            spellCheck
            {...editorProps}
          />
        </EditorWrapper>
      );
    }
  };
}
