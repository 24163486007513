import defaultLoadable from '@loadable/component';
import React from 'react';

import LoadingIndicator from '~/components/LoadingIndicator';

export default function loadable(importFn, opts) {
  return defaultLoadable(importFn, {
    fallback: <LoadingIndicator block p={4} data-loadable-fallback />,
    ...opts,
  });
}
