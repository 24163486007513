import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/components/Button';
import Popover from '~/components/Popover';

import applyPlaceholder from './applyPlaceholder';
import PlaceholderDialog from './PlaceholderDialog';

export default class PlaceholderButton extends React.Component {
  static displayName = 'PlaceholderButton';

  static propTypes = {
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
  };

  state = {
    placeholder: '',
    fallback: '',
  };

  confirm = (placeholder, fallback) => {
    const editorState = applyPlaceholder(
      this.props.getEditorState(),
      placeholder,
      fallback
    );

    this.props.setEditorState(editorState);
  };

  render() {
    return (
      <Popover
        content={({ close }) => (
          <PlaceholderDialog
            close={close}
            confirm={(...args) => {
              this.confirm(...args);
              close();
            }}
            placeholders={this.props.placeholders}
            placeholder=""
            fallback=""
          />
        )}
      >
        <Button type="button" py={1}>
          {'Insert {Placeholder}'}
        </Button>
      </Popover>
    );
  }
}
