import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';

export const isPromiseAction = ({ meta }) => meta && meta.defer;
const matchesTypeAndId =
  (mtype, mid) =>
  ({ type, meta }) =>
    mtype === type && meta?.actionId === mid;

export function* handleActionSaga({ payload, meta }) {
  const { request, defer, types, actionId } = meta;
  const { resolve, reject } = defer;
  const [SUCCESS, FAILURE] = types;

  const [{ success, failure }] = yield all([
    race({
      success: take(matchesTypeAndId(SUCCESS, actionId)),
      failure: take(matchesTypeAndId(FAILURE, actionId)),
    }),
    request && put(request(payload)),
  ]);

  if (success) {
    yield call(resolve, success.payload);
  } else {
    yield call(reject, failure && failure.payload ? failure.payload : failure);
  }
}

export function* promiseWatcherSaga() {
  yield takeEvery(isPromiseAction, handleActionSaga);
}

export default promiseWatcherSaga;
