/*
 *
 * LanguageProvider reducer
 *
 */

import { currentUserFind, currentUserUpdate } from '~/lib/api';

import { DEFAULT_LOCALE } from '../App/constants';
import { CHANGE_LOCALE } from './constants';

// Prevents the errors for missing messages for the current locale in
// development because we are not currently translating any messages
const lang = navigator?.language || DEFAULT_LOCALE;

const initialState = {
  locale: lang || DEFAULT_LOCALE,
};

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case currentUserFind.SUCCESS:
    case currentUserUpdate.SUCCESS:
      return {
        ...state,
        locale: action.payload.user.locale || state.locale,
      };
    default:
      return state;
  }
}

export default languageProviderReducer;
