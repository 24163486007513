import React from 'react';

import Svg from './Svg';

export default function MyobIcon(props) {
  return (
    <Svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Myob</title>
      <defs>
        <linearGradient id="linear1">
          <stop offset="0%" stopColor="rgb(38.038635%,0%,64.704895%)" />
          <stop offset="100%" stopColor="rgb(92.87262%,2.349854%,46.687317%)" />
        </linearGradient>
      </defs>
      <path
        d="m22.56585,10.65915c-2.10096,-2.5568 -5.3387,-4.14565 -9.31365,-4.14565c-8.00805,0 -13.17605,5.62195 -13.17605,13.06162l0,16.92401l2.44237,0c1.66201,-0.10317 4.94102,-0.92667 5.05357,-5.7739l0,-11.32082c0,-3.29401 2.32982,-5.56568 5.68011,-5.56568c4.94102,0 5.45125,3.91867 5.45125,5.56568l0,17.09472l2.44612,0c1.65451,-0.10317 4.91476,-0.92104 5.05169,-5.72137l0,-11.37334c0,-3.29401 2.38422,-5.56568 5.67823,-5.56568c4.94102,0 5.5094,3.91867 5.5094,5.56568l0,17.09472l2.43862,0c1.66951,-0.10317 4.97854,-0.9323 5.05732,-5.85081l0,-11.0732c0,-7.66665 -4.82659,-13.06162 -13.00534,-13.06162c-3.80424,0 -7.09826,1.58885 -9.31365,4.14565"
        fill="url(#linear1)"
      />
    </Svg>
  );
}
