/**
 * Select tag
 */
import { Select as BaseSelect } from 'evergreen-ui';
import { isArray, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Label from '../LabelledInput';

export class Select extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  isArrayOfObjects = (values) => typeof values[0]?.value !== 'undefined';

  getOptions = () => {
    const { values } = this.props;

    if (isArray(values)) {
      // Allow a structure like [{ text: 'An option', value: 'an_option' }, ...]
      // to be passed as values
      if (this.isArrayOfObjects(values)) {
        return values.map(({ text, label, value, disabled }) => ({
          label: label || text,
          value,
          disabled,
        }));
      } else {
        return values.map((value) => ({
          label: value,
          disabled: false,
          value,
        }));
      }
    }

    // This is an object that is being used as a key: value map.
    // So we cannot have disabled options in this type of argument.
    return map(values, (label, value) => ({
      label,
      value,
      disabled: false,
    }));
  };

  render() {
    let options = this.getOptions();
    const {
      values,
      alignRight,
      placeholder,
      onClear,
      value,
      label,
      description,
      footer,
      ...props
    } = this.props;
    const selected = options.find(
      (v) => value?.toString() === v.value?.toString()
    );

    // If we've been passed a value, but it doesnt match any of the options
    // then we want to show that as a disabled option
    const missingValue =
      value && !selected ? <option disabled value={value}></option> : null;

    const innerSelect = (
      <BaseSelect flex="0 1 auto" {...props} value={value}>
        {placeholder ? <option value="">{placeholder}</option> : null}
        {missingValue}
        {options.map(({ label, value, disabled }) =>
          disabled ? (
            <option key={value || 'none'} value={value} disabled>
              {label}
            </option>
          ) : (
            <option key={value || 'none'} value={value}>
              {label}
            </option>
          )
        )}
      </BaseSelect>
    );

    if (!label) {
      return innerSelect;
    }

    return (
      <Label text={label} description={description} footer={footer}>
        {innerSelect}
      </Label>
    );
  }
}

export default function SelectWithSpreadFieldProps({ field, form, ...props }) {
  return <Select {...field} {...props} />;
}
