import { compose } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

import Input from '~/components/Form/TextInput';
import { injectIntl } from '~/components/Intl';

export const dateFormat = {
  year: 'numeric',
  month: 'long',
  day: '2-digit',
};

function IntlDateInput({ value, onChange, intl }) {
  const handleBlur = (e) => {
    onChange({
      target: { value: intl.formatDate(e.target.value, dateFormat) },
    });
  };

  const date = intl.formatDate(value, dateFormat);

  return <Input defaultValue={date} onBlur={handleBlur} />;
}

IntlDateInput.propTypes = {
  intl: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default compose(injectIntl)(IntlDateInput);
