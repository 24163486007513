import { call, takeEvery } from 'redux-saga/effects';

export function* handleAppLoaded() {
  yield call(removeLoading);
}

export function* appLoadingSaga() {
  // WARNING: if we require the user module it will require it for
  // public pages. But
  yield takeEvery('CURRENT_USER_FIND_SUCCESS', handleAppLoaded);
  const location = yield call(currentLocation);

  if (!location.match(/^\/(account|o)/)) {
    yield call(handleAppLoaded);
  }
}

function removeLoading() {
  document.body.classList.remove('loading');
}

function currentLocation() {
  return window.location.pathname + window.location.search;
}

export default appLoadingSaga;
