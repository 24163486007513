export const API_ROOT = import.meta.env?.VITE_API_ROOT_URL;

const isProdOrDev =
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'development';

// Prevents this from causing issues with Cypress, where import.meta.env is empty
if (typeof Cypress === 'undefined' && isProdOrDev && !API_ROOT) {
  throw new Error('No API_ROOT_URL env variable found');
}

/* eslint-disable quote-props */
export const API_HEADERS = {
  Accept: import.meta.env?.VITE_API_HEADER_ACCEPT,
  'Content-Type': 'application/json',
  Context: import.meta.env?.VITE_API_HEADER_CONTEXT,
};
/* eslint-enable quote-props */

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const CANCEL = 'CANCEL';
export const INVALIDATE = 'INVALIDATE';
export const Types = {
  REQUEST,
  SUCCESS,
  FAILURE,
  CANCEL,
  INVALIDATE,
};

export const StatusTypes = {
  NOT_LOADED: 'notLoaded',
  INVALIDATED: 'invalidated',
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed',
};
