import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import Alert from '~/components/Alert';
import Box from '~/components/Box';

const Form = ({
  formik: { handleReset, handleSubmit, ...formikBag },
  errors,
  children,
  ...props
}) => {
  const title = props.title || props.name;
  const baseErrors = errors?.base || formikBag?.errors?.base;

  return (
    <Box
      is="form"
      onReset={(event) => {
        event.stopPropagation();
        handleReset(event);
      }}
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(event);
      }}
      {...props}
    >
      {title && <title>{title}</title>}
      {errors && errors.form && (
        <Alert appearance="card" intent="danger" mb={2}>
          {errors.form}
        </Alert>
      )}
      {baseErrors?.map((b) => (
        <Alert key={b} appearance="card" intent="danger" mb={2}>
          {b}
        </Alert>
      ))}
      {children}
    </Box>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.object,
};

export default connect(Form);
