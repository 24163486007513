import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

export { default } from './ui';

export const selectCurrentOrganisationId = get('currentOrganisation.id');

export const selectCurrentOrganisation = createSelector(
  get('entities.organisations'),
  selectCurrentOrganisationId,
  (organisations, id) => organisations && organisations[id]
);

export const selectCurrentOrganisationIdFromStore = selectCurrentOrganisationId;

export const selectCurrentOrganisationFromStore = createSelector(
  [get('entities.organisations'), selectCurrentOrganisationIdFromStore],
  (orgs, id) => orgs[id]
);

export const selectOrganisationFromPropId = (state, props) =>
  get(`entities.organisations[${props.organisationId}]`, state);

export const selectOrganisationBaseCurrency = createSelector(
  [selectCurrentOrganisationFromStore],
  get('baseCurrency')
);

export const selectOrganisationCountryCode = createSelector(
  [selectCurrentOrganisationFromStore],
  get('countryCode')
);

export const selectOrganisationFlags = createSelector(
  [selectCurrentOrganisationFromStore],
  ({ flags, provider } = {}) => ({ ...flags, provider })
);

export const selectOrganisationFlagByName = (flagName) =>
  createSelector([selectOrganisationFlags], get(flagName));

export const selectOrganisationSettings = createSelector(
  selectCurrentOrganisationFromStore,
  get('settings')
);

export const makeSelectOrganisationSetting = (settingName) =>
  createSelector(selectOrganisationSettings, get(settingName));
