import { get } from 'lodash/fp';
import { handleActions } from 'redux-actions';

import { forgotPassword } from '~/lib/api';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = false;
export default handleActions(
  {
    [forgotPassword.SUCCESS]: () => true,
  },
  initialState
);

export const key = 'ui.resetPassword.forgotSent';

// ------------------------------------
// Selectors
// ------------------------------------

const selectForgot = get(key);

export const makeSelectSent = () => selectForgot;
