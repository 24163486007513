import { compose } from 'lodash/fp';

import styled, {
  bottom,
  color,
  left,
  position,
  right,
  size,
  space,
  style,
  top,
  zIndex,
} from '~/lib/styled';

const iconStyles = style({
  prop: 'size',
  key: 'iconSizes',
  scale: {
    xs: '0.5em',
    sm: '0.75em',
    md: '1em',
    lg: '5em',
    '2x': '2em',
    '3x': '3em',
    '4x': '4em',
    '5x': '5em',
    '6x': '6em',
  },
});

const iconSize = compose(size, iconStyles);

iconSize.propTypes = {
  ...iconStyles.propTypes,
};

const Svg = styled('svg')(
  space,
  color,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  iconSize
);

export default Svg;
