import { isArray,mergeWith } from 'lodash/fp';
import { union } from 'lodash/fp';
import { handleActions } from 'redux-actions';

import { Types } from '../constants';
import slice from '../helpers/sliceReducer';

const initialState = {};
const customMerge = mergeWith((objValue, srcValue) =>
  isArray(srcValue) && isArray(objValue) ? union(srcValue, objValue) : undefined
);

export default slice(
  handleActions(
    {
      [Types.REQUEST]: (state, { payload }) => {
        const page =
          payload && (payload.page || (payload.params && payload.params.page));
        return page === 1 ? initialState : state;
      },
      [Types.SUCCESS]: (state, { payload }) => {
        if (!payload) {
          return state;
        }

        const { result, entities } = payload;

        // If this has not been normalized then just store the whole payload
        return entities && result
          ? customMerge(Object.assign({}, state), result)
          : customMerge(state, payload);
      },
    },
    initialState
  )
);
