import { Option, OptionsList } from 'evergreen-ui';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '~/components/Button';
import Flex from '~/components/Flex';
import { Input } from '~/components/Form';
import Heading from '~/components/Heading';
import Pane from '~/components/Pane';
import Text from '~/components/Text';

const scrollableContainerStyles = { overflowY: 'scroll' };

const optionRenderer = (props) => (
  <Option {...props}>
    <Text color="textMuted" is="span" mr={1}>
      {props.item.group} &raquo;
    </Text>
    {props.label}
  </Option>
);

export default class PopoverDialog extends React.Component {
  static propTypes = {
    hidePlaceholders: PropTypes.bool,
    placeholder: PropTypes.string,
    placeholders: PropTypes.array,
    fallback: PropTypes.string,
    close: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
  };

  static defaultProps = {
    hidePlaceholders: false,
    placeholder: '',
    placeholders: [],
    fallback: '',
  };

  state = {
    filter: '',
    dirty: false,
    placeholder: this.props.placeholder,
    fallback: this.props.fallback,
  };

  handlePlaceholderChange = (placeholder) =>
    this.setState({
      dirty: true,
      placeholder: placeholder.value,
    });

  handleFallbackChange = (e) =>
    this.setState({
      dirty: true,
      fallback: e.target.value,
    });

  handleFilterChange = (e) => this.setState({ filter: e.target.value });

  handleConfirm = (e) => {
    e.preventDefault();
    this.props.confirm(this.state.placeholder, this.state.fallback);
  };

  render() {
    const { placeholders } = this.props;
    const { placeholder, fallback, filter, dirty } = this.state;
    let placeholderList = placeholders;

    if (filter) {
      const textMatch = filter.toLowerCase().replace([' ', '.'], '');
      placeholderList = placeholderList.map((group) => ({
        ...group,
        items: group.items.filter(
          (item) =>
            item.label.toLowerCase().replace(' ', '').includes(textMatch) ||
            item.value.replace('.', '').includes(textMatch)
        ),
      }));
      placeholderList = placeholderList.filter((group) => group.items.length);
    }

    const flatOptionsList = placeholderList.reduce(
      (items, g) =>
        items.concat(g.items.map((i) => ({ ...i, group: g.label }))),
      []
    );

    return (
      <div>
        <Heading p={2} size={400}>
          Placeholders
        </Heading>

        <Pane
          borderTop="default"
          borderBottom="default"
          fontSize={0}
          py={2}
          px={2}
        >
          <Input
            type="text"
            placeholder="Filter…"
            onChange={this.handleFilterChange}
            value={filter}
            onClick={(e) => e.stopPropagation()}
            tabIndex="0"
          />
        </Pane>

        {!this.props.hidePlaceholders && (
          <Pane
            borderTop="default"
            borderBottom="default"
            maxHeight={250}
            width={300}
            boxShadow="scrollableBox"
            css={scrollableContainerStyles}
          >
            <OptionsList
              height={33 * flatOptionsList.length}
              onSelect={this.handlePlaceholderChange}
              hasFilter={false}
              options={flatOptionsList}
              renderItem={optionRenderer}
              selected={[placeholder]}
            />
          </Pane>
        )}

        <Text fontSize={0} px={2} my={2}>
          <Input
            type="text"
            placeholder="Fallback (optional)"
            onChange={this.handleFallbackChange}
            value={fallback}
            onClick={(e) => e.stopPropagation()}
            tabIndex="0"
          />
        </Text>

        <Pane borderTop="default">
          <Flex p={2} justifyContent="space-between">
            <Button
              type="button"
              appearance="minimal"
              mr={1}
              onClick={this.props.close}
            >
              Cancel
            </Button>
            <Button
              type="button"
              appearance="primary"
              mr={1}
              intent="success"
              disabled={!dirty}
              onClick={this.handleConfirm}
            >
              Insert
            </Button>
          </Flex>
        </Pane>
      </div>
    );
  }
}
