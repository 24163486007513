import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import parse from '~/utils/parseQuery';

export default function useSearchQuery() {
  const location = useLocation();
  const query = useMemo(() => parse(location), [location]);

  return query;
}
