import { get } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { UPDATE_APP_ERROR } from '~/actions/error';

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { code: 0, message: '' };
export default handleActions(
  {
    [UPDATE_APP_ERROR]: (state, { payload }) => ({
      ...state,
      code: payload.code,
      message: payload.message,
      title: payload.title,
    }),
  },
  initialState
);

export const key = 'ui.error';

// ------------------------------------
// Selectors
// ------------------------------------

export const selectAppError = get(key);

export const selectAppErrorCode = createSelector(selectAppError, get('code'));

export const selectAppErrorMessage = createSelector(
  selectAppError,
  get('message')
);

export const selectAppErrorVisible = createSelector(
  selectAppErrorCode,
  (code) => code > 0
);
