import { createAction } from 'redux-actions';

// ------------------------------------
// Constants
// ------------------------------------
export const TOKEN_SET = 'TOKEN_SET';
export const UNAUTHORISED = 'UNAUTHORISED';

// ------------------------------------
// Actions
// ------------------------------------
export const tokenSet = createAction(TOKEN_SET);
export const unauthorised = createAction(UNAUTHORISED);
