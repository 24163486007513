export default function createErrorActionLogger() {
  return () => (next) => (action) => {
    try {
      return next(action);
    } catch (e) {
      console.info(action);
      console.error(e);
      throw e;
    }
  };
}
