/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import createErrorActionLogger from './utils/errorActionLogger';
import combineReducerTree from './utils/injectors/combineReducerTree';
import {
  createErrorReporterEnhancer,
  createRecordingMiddleware,
} from './utils/instrumentation';
import createTracker from './utils/tracker';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the redux-first middlewares
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history,
    });

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [
    sagaMiddleware,
    routerMiddleware,
    createTracker,
    createRecordingMiddleware(),
  ];

  if (import.meta.env.MODE !== 'production') {
    // const freeze = require('redux-freeze'); // eslint-disable-line global-require
    // middlewares.push(freeze);

    // Add an error action logger
    middlewares.unshift(createErrorActionLogger());
  }

  const enhancers = [
    applyMiddleware(...middlewares),
    createErrorReporterEnhancer(),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    import.meta.env.MODE !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(routerReducer),
    initialState,
    composeEnhancers(...enhancers)
  );

  // Enable async replacing reducers
  store.history = createReduxHistory(store);
  store.routerReducer = routerReducer;

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (import.meta.hot) {
    import.meta.hot.accept('./reducers', () => {
      const nextReducers = createReducer(
        routerReducer,
        combineReducerTree(store.injectedReducers)
      );
      store.replaceReducer(nextReducers);
    });
  }

  return store;
}
