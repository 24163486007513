/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { get, identity } from 'lodash/fp';
import { combineReducers } from 'redux';

import languageProviderReducer from '~/components/LanguageProvider/reducer';
import { reducer as apiReducer } from '~/lib/api';
import createAuthReducer from '~/lib/authentication/reducer';
import createRootReducer from '~/reducers/root';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(routerReducer, injectedReducers) {
  return createRootReducer(
    createAuthReducer(
      combineReducers({
        router: routerReducer,
        language: languageProviderReducer,
        api: apiReducer,
        ...injectedReducers,
      })
    )
  );
}

/**
 * Creates a selector that selects a prop from props
 *
 * @param {string} prop Prop to select from props
 * @param {function} transform Transform function to apply to the prop (optional)
 * @returns {function} Selector
 */
export const makeSelectProp = (prop, transform = identity) => {
  const selectProp = get(prop);
  return (state, props) => transform(selectProp(props));
};

export const makeSelectPart = (selector, prop) => {
  const selectProp = get(prop);
  return (state, props) => selectProp(selector(state, props));
};
