/**
 * Checkbox tag
 */
import React from 'react';

import Input from '../Input';
import Label from './Label';

const Checkbox = ({ label, ...props }) => {
  const { field = {}, form = {} } = props;
  let isChecked = {};

  if (typeof field.value === 'boolean') {
    isChecked = { checked: field.value };
  } else if (form.values && form.values[field.name] === props.value) {
    isChecked = { checked: true };
  } else if (field.name || 'checked' in props) {
    isChecked = { checked: false };
  }

  if (label) {
    return (
      <Label>
        <Input type="checkbox" role="checkbox" {...isChecked} {...props} />
        <Label.TextWrapper>{label}</Label.TextWrapper>
      </Label>
    );
  }

  return <Input role="checkbox" type="checkbox" {...isChecked} {...props} />;
};

export default Checkbox;
