/**
 * Label with an input below
 */
import PropTypes from 'prop-types';
import React from 'react';

import Heading from '~/components/Heading';
import Text from '~/components/Text';
import ourStyled from '~/lib/styled';

const Label = ourStyled(Text, {
  is: 'label',
  lineHeight: 'copy',
})();

const LabelledInput = ({ text, description, footer, children, ...props }) => (
  <Label {...props}>
    {text && (
      <Heading size={400} mb={1}>
        {text}
      </Heading>
    )}
    {description && (
      <Text fontSize={0} mb={1}>
        {description}
      </Text>
    )}
    {children}
    {footer && (
      <Text fontSize={0} color="muted" my={1}>
        {footer}
      </Text>
    )}
  </Label>
);

LabelledInput.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LabelledInput;
