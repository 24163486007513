/**
 * Adds a sticker to an editor state
 */

import { EditorState, Modifier } from 'draft-js';

import getEntityRange from '../../utils/getEntityRange';
import { ENTITY_TYPE } from './constants';

export default (editorState, href, target) => {
  const currentContentState = editorState.getCurrentContent();
  const currentSelectionState = editorState.getSelection();
  const selectionKey = currentSelectionState.getAnchorKey();
  const block = currentContentState.getBlockForKey(selectionKey);
  let selectionState = currentSelectionState;
  let afterEntityCreation = currentContentState;
  let lastEntityKey = null;

  const entityKey = block.getEntityAt(currentSelectionState.getAnchorOffset());

  // If the selection is collapsed, and we are not creating
  // a link, then we are trying to update a link for the
  // word we are in the middle of.
  if (entityKey && currentSelectionState.isCollapsed()) {
    const entityRange = getEntityRange(editorState, entityKey);

    selectionState = currentSelectionState.merge({
      anchorOffset: entityRange.start,
      focusOffset: entityRange.end,
    });
  }

  // If we have an href we are either creating or
  // modifying an existing entity
  if (href) {
    if (entityKey) {
      afterEntityCreation = currentContentState.replaceEntityData(entityKey, {
        href,
        target,
      });

      lastEntityKey = entityKey;
    } else {
      afterEntityCreation = currentContentState.createEntity(
        ENTITY_TYPE,
        'MUTABLE',
        { href, target }
      );

      lastEntityKey = afterEntityCreation.getLastCreatedEntityKey();
    }
  }

  const newContentState = Modifier.applyEntity(
    afterEntityCreation,
    selectionState,
    lastEntityKey
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    'apply-entity'
  );

  return EditorState.forceSelection(
    newState,
    newContentState.getSelectionAfter()
  );
};
