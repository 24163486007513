import { InlineAlert } from 'evergreen-ui';
import { connect, getIn } from 'formik';
import { decamelize } from 'humps';
import { castArray, last, upperFirst } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

import Alert from '~/components/Alert';
import Box from '~/components/Box';
import Button from '~/components/Button';

function FieldError({ name, appearance, formik, label, requireTouch }) {
  const error = getIn(formik.errors, name);
  const touch = getIn(formik.touched, name);

  if ((touch || !requireTouch) && error) {
    const fieldName =
      label ||
      upperFirst(decamelize(last(name.split('.')), { separator: ' ' }));
    return (
      <ShowMoreErrors
        fieldName={fieldName}
        errors={castArray(error)}
        appearance={appearance}
      />
    );
  }

  return null;
}

FieldError.defaultProps = {
  appearance: 'default',
  requireTouch: true,
};

FieldError.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf(['default', 'alert']),
};

export default connect(FieldError);

const VISIBLE_ERROR_LIMIT = 5;
function ShowMoreErrors({ fieldName, errors, appearance }) {
  const [showMore, setShowMore] = React.useState(false);
  const visibleErrors = errors.slice(
    0,
    showMore ? errors.length : VISIBLE_ERROR_LIMIT
  );

  return (
    <Box my={2}>
      {visibleErrors.map((e) =>
        appearance === 'alert' ? (
          <Alert intent="danger" appearance="card" mb={1} key={e}>
            {fieldName} {e}
          </Alert>
        ) : (
          <InlineAlert intent="danger" textAlign="left" key={e}>
            {fieldName} {e}
          </InlineAlert>
        )
      )}
      {errors.length > VISIBLE_ERROR_LIMIT ? (
        <Button
          type="button"
          appearance="minimal"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show less' : 'Show more…'}
        </Button>
      ) : null}
    </Box>
  );
}
