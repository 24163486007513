import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonGroup } from '~/components/Button';

class Toolbar extends React.Component {
  static propTypes = {
    getEditorState: PropTypes.func,
    setEditorState: PropTypes.func,
    focus: PropTypes.func,
    inlineButtons: PropTypes.array,
    blockButtons: PropTypes.array,
    affixed: PropTypes.bool,
  };

  renderButtons(buttons) {
    const { inlineButtons, blockButtons, ...props } = this.props;

    if (!isFunction(props.getEditorState)) {
      return null;
    }

    return buttons.map((Button) => (
      <Button key={`button-${Button.displayName}`} {...props} />
    ));
  }

  renderInlineButtons() {
    return this.renderButtons(this.props.inlineButtons);
  }

  renderBlockButtons() {
    return this.renderButtons(this.props.blockButtons);
  }

  render() {
    return (
      <ButtonGroup mb={2} display="inline-block">
        {this.renderInlineButtons()}
        {this.renderBlockButtons()}
      </ButtonGroup>
    );
  }
}

export default Toolbar;
